import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import WebsiteButton from '@/components/website-button/WebsiteButton'
import WebsiteTypography from '@/components/websiteTypography/WebsiteTypography'
import { useOrganizationsQuery } from '@/graphql'
import { scrollToSection } from '@/helpers/scrollToSection'
import { websiteRoutes } from '@/router/routes'
import { EASETECH_COMPANY } from '@/utilities/global-variables'
import { Skeleton } from 'antd'

import { FC } from 'react'
import { useNavigate, useParams } from 'react-router'
import './HomeHero.less'
import background from './images/hero-bg.webp'

const { Title, Paragraph, Text } = WebsiteTypography

type Props = {
  content?: ComponentUiCard
  loading: boolean
}
const HomeHero: FC<Props> = ({ content, loading }) => {
  const navigate = useNavigate()
  const { website } = websiteRoutes
  const titleArray = content?.title?.split(' ')
  const middleTitleString = titleArray?.slice(1, -1).join(' ')
  const params = useParams()
  const currentOrgSlug = params.org

  const {data, loading: orgLoading} = useOrganizationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: {slug: {eqi: currentOrgSlug}}
    }
  })
  const currentOrganization = data?.organizations?.data?.[0]

  const fontColor = currentOrgSlug === EASETECH_COMPANY ? 'white' : currentOrganization?.attributes?.fontColor ? currentOrganization?.attributes?.fontColor : 'white'

  const onGetStarted = () => {
    navigate(website.quote)
  }
  return (
    <div className={'home-hero'}>
      <div className={'container'}>
        <div className={'hero-content-wrapper'}>
          <div className={'hero-content'}>
            {loading || orgLoading ? (
              <div className={'title-wrapper'}>
                <Skeleton paragraph={{ rows: 8 }} active />
              </div>
            ) : (
              <div className={'title-wrapper'}>
                <Title level={1} color={'white'} className={'hero-title'} style={{color: fontColor}}>
                  {currentOrgSlug === EASETECH_COMPANY ? (
                    <>
                      <span className={'bold'}>{titleArray?.[0]}</span>
                      <span>{` ${middleTitleString} `}</span>
                      <span className={'bold'}>{titleArray?.[titleArray?.length - 1]}</span>
                    </>
                  ) : (
                    <span className={'bold'}>{currentOrganization?.attributes?.heroSection?.title}</span>
                  )}

                </Title>
                <Paragraph size={'lg'} color={'white'} weight={'w700'} className={'hero-text'} style={{color: fontColor}}>
                  {currentOrgSlug === EASETECH_COMPANY ? content?.subtitle : currentOrganization?.attributes?.heroSection?.subtitle}
                </Paragraph>
                <Paragraph size={'md'} color={'website-secondary'} weight={'w400'}
                           className={'hero-text hero-secondary'} style={{color: fontColor}}>
                  {currentOrgSlug === EASETECH_COMPANY ? content?.description : currentOrganization?.attributes?.heroSection?.description}
                </Paragraph>
              </div>
            )}
            {currentOrgSlug === EASETECH_COMPANY && (
              <div className={'btn-wrapper'}>
                <WebsiteButton onClick={onGetStarted} style={{ marginRight: 8 }}>
                  <Text>{'GET A FREE QUOTE'}</Text>
                </WebsiteButton>
                <WebsiteButton btnType={'ghost-primary'} onClick={() => scrollToSection('contact')}>
                  <Text color={'orange'}>{'CONTACT FOR MORE INFO'}</Text>
                </WebsiteButton>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={'bg-image-wrapper'}>
        {currentOrgSlug === EASETECH_COMPANY ? (
        <img src={background} alt={''} />
          ) : (
              <img src={currentOrganization?.attributes?.heroSection?.media?.data?.attributes?.url} alt={''} />
      )}
      </div>
    </div>
  )
}

export default HomeHero










