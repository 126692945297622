import { useAuth } from '@/components/context/AuthProvider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import Typography from '@/components/typography/Typography'
import UserAvatar from '@/components/user-avatar/UserAvatar'
import { useUserProfilesQuery } from '@/graphql'
import { setAuthToken } from '@/helpers/auth'
import { getInitials } from '@/helpers/getInitials'
import { websiteRoutes } from '@/router/routes'
import { Divider, Popover, Skeleton } from 'antd'
import classNames from 'classnames'
import { FC, Suspense, useState } from 'react'
import { useNavigate } from 'react-router'
import './Avatar.less'
import { Link } from 'react-router-dom'

const { Text, Paragraph } = Typography

const Avatar: FC<{ collapsed: boolean }> = ({ collapsed }) => {
  const { user, role, organization } = useAuth()
  const navigate = useNavigate()
  const { website, dashboard } = websiteRoutes
  const [open, setOpen] = useState(false)


  const { data, loading: userProfileLoading } = useUserProfilesQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { filter: { user: { id: { eq: user?.id } } } },
  })
  const profileData = data?.userProfiles?.data?.[0]

  const avatarImage = profileData?.attributes?.avatar?.data?.attributes?.url
  const userFullName = user?.userProfile?.data?.attributes?.fullName
  const isAdmin = user?.agentStatus === 'admin'

  const classes = classNames({
    'avatar-content': true,
    'collapsed': collapsed,
  })
  const avatarClasses = classNames({
    'avatar-full-name': true,
    'hidden': collapsed,
  })
  const avatarNameWrapperClasses = classNames({
    'name-wrapper': true,
    'hidden': collapsed,
  })

  const onLogout = () => {
    navigate(`/${organization}${website.home}`)
    setAuthToken('')
    localStorage.clear()
  }
  const content = !user || userProfileLoading ? (
    <Skeleton loading={true} paragraph={{ rows: 5 }} />
  ) : (
    <Suspense fallback={<Skeleton loading={true} paragraph={{ rows: 5 }} />}>
      <div className={'avatar-popover'}>
        <div className={'header-wrapper'}>
          <div className={'popover-avatar-wrapper'}>
            {avatarImage ? <img src={avatarImage} alt={'avatar'} /> : (
              <Text size={'xs'} weight={'w600'} className={'avatar-text'}>{getInitials(userFullName || '')}</Text>
            )}
          </div>
          <div className={'info-block'}>
            <Text size={'md'} weight={'w600'}>
              {userFullName ? userFullName : 'Your name...'}
            </Text>
            <Paragraph color={'gray'}>{user?.email}</Paragraph>
          </div>
        </div>
        <Divider />
        <div
          className={'links-wrapper'}
          onClick={() => setOpen(false)}
        >
          <Link to={`${dashboard.profile}/${role}/${profileData?.id}/profile`} className={'popover-item'}>
            <Text size={'sm'}>{'Profile'}</Text>
          </Link>
          <Link to={'#'} className={'popover-item'} onClick={onLogout}>
            <Text size={'sm'}>{'Log Out'}</Text>
          </Link>
        </div>
      </div>
    </Suspense>
  )
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  return (
    <div className={classes}>
      <Popover
        placement={'topLeft'}
        style={{ position: 'fixed' }}
        title={''}
        content={content}

        trigger={'click'}
        overlayStyle={{ minWidth: 150 }}
        overlayClassName={'sidebar-avatar-popover'}
        onOpenChange={handleOpenChange}
        open={open}
      >
        <div>
          <UserAvatar avatarImageUrl={avatarImage} userFullName={userFullName || ''} isAdmin={isAdmin} />
        </div>
      </Popover>
      <div className={avatarNameWrapperClasses}>
        <Text color={'website-secondary'} className={avatarClasses}>{`Hi, ${userFullName} `}</Text>
      </div>
    </div>
  )
}

export default Avatar
