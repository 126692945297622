import { SvgIcon } from '@/components/icon'
import WebsiteButton from '@/components/website-button/WebsiteButton'
import WebsiteTypography from '@/components/websiteTypography/WebsiteTypography'
import { scrollToSection } from '@/helpers/scrollToSection'
import { websiteRoutes } from '@/router/routes'
import { FC } from 'react'
import './Footer.less'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import fbIcon from './images/fb.png'
import sectionBg from './images/footer-bg.png'
import instaIcon from './images/instagram.png'
import linkedIcon from './images/lingedin.png'
import logoWhite from './images/logo_company-white.png'

const { Text } = WebsiteTypography


const Footer: FC = () => {
  const navigate = useNavigate()
  const { website } = websiteRoutes

  const params = useParams()
  const currentOrgSlug = params.org

  const onServicesClick = () => {
    scrollToSection('services')
  }
  const onPrivacyClick = () => {
    navigate(website.privacyPolicy)
  }
  const onTermsClick = () => {
    navigate(website.termsAndConditions)
  }
  return (
    <div className={'footer-section-wrapper'}>
      <div className={'footer-bg-wrapper'}>
        <img src={sectionBg} alt={''} />
      </div>
      <div className={'container'}>
        <div className={'footer-section-content'}>
          <div className={'logo-block'}>
            <div className={'logo-wrapper'}>
              <img src={logoWhite} alt={''} />
            </div>
            <div className={'soc-buttons-block'}>
              <WebsiteButton btnType={'icon'} className={'soc-btn sm'}>
                <SvgIcon type={'footer-soc-btn'} />
                <img src={fbIcon} alt={''} />
              </WebsiteButton>
              <WebsiteButton btnType={'icon'} className={'soc-btn'}>
                <SvgIcon type={'footer-soc-btn'} />
                <img src={instaIcon} alt={''} />
              </WebsiteButton>
              <WebsiteButton btnType={'icon'} className={'soc-btn'}>
                <SvgIcon type={'footer-soc-btn'} />
                <img src={linkedIcon} alt={''} />
              </WebsiteButton>
            </div>
            <div className='terms-block'>
              <WebsiteButton btnType={'text'} onClick={onTermsClick}>
                <Text color={'website-secondary'} size={'sm'}>{'Terms and conditions'}</Text>
              </WebsiteButton>
              <div className={'footer-divider'} />
              <WebsiteButton btnType={'text'} onClick={onPrivacyClick}>
                <Text color={'website-secondary'} size={'sm'}>{'Privacy policy'}</Text>
              </WebsiteButton>
            </div>
            <div className={'rights-reserved-block'}>
              <Text color={'website-secondary'} weight={'w300'} size={'sm'} className={'rights-text'}>
                © 2024
                <span>
                 {' easetech. '}
               </span>
                All Rights Reserved
              </Text>
            </div>
          </div>
          <div className={'content-block'}>
            <div className={'navigation'}>
              <div className={'footer-content-title'}>
                <Text weight={'w700'} size={'md'}>{'Navigation'}</Text>
              </div>
              <WebsiteButton btnType={'text'} onClick={() => scrollToSection('about')}>
                <Text color={'website-secondary'} size={'sm'}>{'About'}</Text>
              </WebsiteButton>
              <WebsiteButton btnType={'text'} onClick={() => scrollToSection('contact')}>
                <Text color={'website-secondary'} size={'sm'}>{'Contact Us'}</Text>
              </WebsiteButton>
              <WebsiteButton btnType={'ghost-white'} size={'small'} block>
                <Link to={`/${currentOrgSlug}${website.auth}/login`}>
                  <Text>
                    {'SIGN IN'}
                  </Text>
                </Link>
              </WebsiteButton>
              <WebsiteButton btnType={'primary'} size={'small'} block>
                <Link to={`/${currentOrgSlug}${website.auth}/client`}>
                  <Text>
                    {'REGISTER'}
                  </Text>
                </Link>
              </WebsiteButton>
            </div>

          </div>
          <div className='content-block links-block'>
            <div className={'footer-content-title'}>
              <Text weight={'w700'} size={'md'}>{'Our Services'}</Text>
            </div>
            <WebsiteButton btnType={'text'} onClick={onServicesClick}>
              <Text color={'website-secondary'} size={'sm'}>{'Municipal Wayleaves'}</Text>
            </WebsiteButton>
            <WebsiteButton btnType={'text'} onClick={onServicesClick}>
              <Text color={'website-secondary'} size={'sm'}>{'Utility Service Wayleaves'}</Text>
            </WebsiteButton>
            <WebsiteButton btnType={'text'} onClick={onServicesClick}>
              <Text color={'website-secondary'} size={'sm'}>{'National Road Wayleave'}</Text>
            </WebsiteButton>
            <WebsiteButton btnType={'text'} onClick={onServicesClick}>
              <Text color={'website-secondary'} size={'sm'}>{'Railway Wayleaves'}</Text>
            </WebsiteButton>
          </div>
          <div className='content-block'>
            <div className={'footer-content-title'}>
              <Text weight={'w700'} size={'md'}>{'Contact us'}</Text>
            </div>
            <div className='contact-us-item'>
              <Text size={'xs'} color={'website-secondary'}>{'Phone:'}</Text>
              <a href='tel:+0826996888'>
                <Text weight={'w700'} size={'sm'}>{'082 699 6888'}</Text>
              </a>

            </div>
            <div className='contact-us-item'>
              <Text size={'xs'} color={'website-secondary'}>{'Email:'}</Text>
              <a href='mailto:info@easetech.co.za'>
                <Text weight={'w300'} size={'sm'} color={'orange'}>{'info@easetech.co.za'}</Text>
              </a>
            </div>
          </div>


        </div>
      </div>

    </div>
  )
}

export default Footer
