import Button from "../../button/Button"
import { useGeneralContext } from "../../context/GeneralContext"
import { SvgIcon } from "../../icon"
import Typography from "../../typography/Typography"

const { Paragraph } = Typography

const PaymentSuccess = () => {
  const { setIsModalOpen } = useGeneralContext()
  const onConfirm = () => {
    // setModalType('create-application-info')
    setIsModalOpen(false)
  }
  return (
    <div className={"create-application-success"}>
      <div className='success-icon-wrapper'>
        <SvgIcon type={"success-tick"} />
      </div>
      <Paragraph centered={true} size={"xl"} style={{ maxWidth: "80%" }} weight={"w700"}>
        {"Payment successful!"}
      </Paragraph>
      <Paragraph centered={true} style={{ margin: "16px 0 0 0" }} size={"sm"}>
        {"Your payment was successful. ..."}
      </Paragraph>
      <div className='btn-block'>
        <Button text={"Okay"} btnType={"primary"} onClick={onConfirm} />
      </div>
    </div>
  )
}
export default PaymentSuccess
