import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import FormItem, { SelectOptionsType } from '@/components/form-item/FormItem'
import FormItemDivider from '@/components/form-item/FormItemDivider'
import MapBoxMap from '@/components/google-map/map-box-map/MapBoxMap'
import MarkerCircle from '@/components/google-map/marker-circle/MarkerCircle'
import { SvgIcon } from '@/components/icon'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import useSaveRecordAsDraft from '@/components/modal/modal-content/hooks/useSaveKickoffRecord'
import MultiSelectUserItem from '@/components/multi-select-user-item/MultiSelectUserItem'
import UploadFileItem from '@/components/upload-file-item/UploadFileItem'
import {
  useApplicationsQuery,
  useDiscussionTopicsQuery, useKickoffInviteLazyQuery,
  useKickoffInvitesQuery,
  useKickoffRecordQuery, useSendRecordMutation,
  useUpdateKickoffRecordMutation,
  useUserQuery,
} from '@/graphql'
import { getAuthToken } from '@/helpers/auth'
import { DefaultFileListItem } from '@/types/upload'
import { getBase64 } from '@/utilities/utilities'
import { Col, Form, message, Modal, Popconfirm, Row, Space, Spin, Upload, UploadFile, UploadProps } from 'antd'
import { UploadChangeParam } from 'antd/es/upload'
import { Feature, FeatureCollection, Point } from 'geojson'
import moment from 'moment'
import { FC, Ref, SetStateAction, useEffect, useRef, useState } from 'react'
import { LngLatLike, MapRef, Marker, MarkerDragEvent } from 'react-map-gl'
import { useSearchParams } from 'react-router-dom'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Title, Text, Paragraph } = Typography
const CreateKickoffRecord: FC = () => {
  const { user, role } = useAuth()
  const mapRef = useRef<MapRef>()
  const [form] = Form.useForm()
  const [searchParams] = useSearchParams()
  const { setIsModalOpen, currentId, setModalWidth } = useGeneralContext()

  const [updateRecord, { loading: recordUpdating }] = useUpdateKickoffRecordMutation()
  const [sendRecord, {loading: sendingRecord}] = useSendRecordMutation()

  const readOnly = role === 'client'

  const uuid = searchParams.get('id') as string

  const [location, setLocation] = useState<Point | null>(null)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState<SetStateAction<string> | undefined>('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [uploadFilesIds, setUploadFilesIds] = useState<Maybe<string>[] | undefined>([])
  const [uploadApprovalFilesIds, setUploadApprovalFilesIds] = useState<Maybe<string>[] | undefined>([])
  const [uploadProgress, setUploadProgress] = useState(0)
  const [defaultFileList, setDefaultFileList] = useState<DefaultFileListItem[]>([])
  const [defaultApprovalFileList, setDefaultApprovalFileList] = useState<DefaultFileListItem[]>([])
  const [selectedInviteId, setSelectedInviteId] = useState<Maybe<string>>('')


  const [fetchInvite] = useKickoffInviteLazyQuery()
  const { data, loading: applicationLoading } = useApplicationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { filter: { uuid: { eq: uuid } } },
  })
  const { data: currentKickoffData, loading: currentKickoffLoading } = useKickoffRecordQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })

  const currentKickoff = currentKickoffData?.kickoffRecord?.data as KickoffRecordEntity

  useEffect(() => {
    if (currentKickoff) {
      const defaultFiles = currentKickoff?.attributes?.photos?.data?.map(it => ({
        uid: it?.id,
        name: it?.attributes?.name,
        status: 'done',
        url: it?.attributes?.url,
        thumbUrl: it?.attributes?.url,
      } as DefaultFileListItem)) || []

      const defaultApprovalFiles = currentKickoff?.attributes?.approvalDocuments?.data?.map(it => ({
        uid: it?.id,
        name: it?.attributes?.name,
        status: 'done',
        url: it?.attributes?.url,
        thumbUrl: it?.attributes?.url,
      } as DefaultFileListItem)) || []

      setUploadFilesIds(currentKickoff?.attributes?.photos?.data?.map(it => it?.id))
      setUploadApprovalFilesIds(currentKickoff?.attributes?.approvalDocuments?.data?.map(it => it?.id))

      setDefaultFileList([...defaultFiles])
      setDefaultApprovalFileList([...defaultApprovalFiles])
      setSelectedInviteId(currentKickoff?.attributes?.kickoffInvite?.data?.id)
    }
  }, [currentKickoff])

  const recipientsEmails = currentKickoff?.attributes?.recipients?.map(it => it?.email).toString()
  const appData = data?.applications?.data?.[0] as ApplicationEntity
  const itemId = currentKickoff?.id
  const applicationCenterFeature = appData?.attributes?.projectCenter as Feature
  const applicationCenter = applicationCenterFeature?.geometry as Point

  const { data: discussionTopicsData, loading: topicsLoading } = useDiscussionTopicsQuery({
    fetchPolicy: globalFetchPolicy,
  })
  const { data: appOwnerData, loading: appOwnerLoading } = useUserQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      id: appData?.attributes?.owner?.data?.id || '',
    },
  })
  const { data: kickoffInvitesData, loading: invitesLoading } = useKickoffInvitesQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: {
        application: {uuid: {eq: uuid}}
      }
    }
  })
  const appOwner = appOwnerData?.usersPermissionsUser?.data as UsersPermissionsUserEntity

  const discussionTopics = discussionTopicsData?.discussionTopics?.data
  const topicsOptions: SelectOptionsType[] = discussionTopics?.map(it => ({
    label: it?.attributes?.title || '',
    value: it?.id || '',
  })) || []

  useEffect(() => {
    if (!applicationLoading && applicationCenter && !currentKickoffLoading && !currentKickoff) {
      setLocation(applicationCenter)
    }
    if (!currentKickoffLoading && currentKickoff) {
      setLocation(currentKickoff?.attributes?.location as Point)
    }

  }, [applicationCenter, applicationLoading, currentKickoff, currentKickoffLoading])

  const { onSaveAsDraft, loading } = useSaveRecordAsDraft({
    mapRef,
    appData,
    location,
  })

  const onMarkerDrag = (e: MarkerDragEvent) => {
    setLocation({
      type: 'Point',
      coordinates: [e.lngLat.lng, e.lngLat.lat],
    })
  }
  const onLoad = () => {
    mapRef?.current?.setCenter(applicationCenter?.coordinates as LngLatLike)
    mapRef?.current?.easeTo({ zoom: 14 })
  }
  const onSaveAndSubmit = async () => {
    const values = await form.validateFields()
    // ===> (temp) const updatedRecordId = await onSaveAsDraft(values, uploadFilesIds, uploadApprovalFilesIds, itemId, appOwner, 'sent')
    const updatedRecordId = await onSaveAsDraft(values, uploadFilesIds, uploadApprovalFilesIds, itemId, selectedInviteId, appOwner, 'sent')

    if(updatedRecordId) {
       await sendRecord({
        fetchPolicy: globalFetchPolicy,
        variables: {
          input: {
            recordId: updatedRecordId as string,
            organizationId: user?.organization?.data?.id || ''
          }
        },
         onCompleted: () => {
           BlackNotification('Record sent successfully')
           setIsModalOpen(false)
           setModalWidth(null)

         },
         onError: (error) => {
           BlackNotification(error.message)
           setIsModalOpen(false)
         },
      })
    }
  }

  const handleSaveAsDraft = async (values: KickoffRecordInput) => {
    await onSaveAsDraft(values, uploadFilesIds, uploadApprovalFilesIds, itemId, selectedInviteId, appOwner, 'draft')
    setIsModalOpen(false)
    setModalWidth(null)
  }
  const inviteOptions: SelectOptionsType[] = kickoffInvitesData?.kickoffInvites?.data?.map(it => ({
    label: `${it?.attributes?.date} (${moment(it?.attributes?.time, 'HH:mm SSS').format('HH:mm A')})`,
    value: it?.id || '',
  })) || []

  const participantsOptions: SelectOptionsType[] = appData?.attributes?.approvalEnvelop?.filter(it => it?.isSiteVisitRequired).map(it => ({
    label: it?.companyName || '',
    value: it?.companyId || '',
  })) || []

  const recipientOptions: SelectOptionsType[] = [
    {
      label: <MultiSelectUserItem
        name={appOwner?.attributes?.userProfile?.data?.attributes?.fullName + ' (client)'}
        email={appOwner?.attributes?.userProfile?.data?.attributes?.email}
      />,
      value: appData?.attributes?.owner?.data?.id,
    },
  ] || []

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = (await getBase64(file.originFileObj as File)) as string
    }
    setPreviewImage(file.url || file.preview)
    if (file.url) setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
    setPreviewVisible(true)
  }
  const props: UploadProps = {
    name: 'files',
    action: `${(import.meta.env.WEBSITE_API_URL ?? '').replace('/graphql', '')}/api/upload`,
    listType: 'picture',
    headers: { Authorization: `Bearer ${getAuthToken()}` },
    multiple: true,
    onPreview: handlePreview,
    showUploadList: true,
    className: 'upload-field',
  }

  const onUploadChange = (
    info: UploadChangeParam<UploadFile>,
  ) => {
    setUploadProgress(info?.file?.percent || 0)
    const uploadedIds = info?.fileList?.map(it => {
      if (it?.response) {
        return it?.response?.[0]?.id
      } else {
        return it?.uid
      }
    })


    setUploadFilesIds([...uploadedIds])
    const { status } = info.file
    // setUploadFileList([...info.fileList])

    if (status === 'done') {
      BlackNotification(`${info.file.name} file uploaded successfully.`)
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`).then()
    }
  }
  const onApprovalUploadChange = (
    info: UploadChangeParam<UploadFile>,
  ) => {
    setUploadProgress(info?.file?.percent || 0)
    const uploadedIds = info?.fileList?.map(it => {
      if (it?.response) {
        return it?.response?.[0]?.id
      } else {
        return it?.uid
      }
    })

    setUploadApprovalFilesIds([...uploadedIds])
    const { status } = info.file
    // setUploadFileList([...info.fileList])

    if (status === 'done') {
      BlackNotification(`${info.file.name} file uploaded successfully.`)
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`).then()
    }
  }
  const handleCancel = () => setPreviewVisible(false)

  let initialValues: KickoffRecordInput = {
    recipients: [appData?.attributes?.owner?.data?.id || ''] as InputMaybe<ComponentDataContactInput>[],
  }
  if (currentKickoff) {
    initialValues = {
      kickoffInvite: currentKickoff?.attributes?.kickoffInvite?.data?.id,
      date: moment(currentKickoff?.attributes?.date, 'YYYY-MM-DD') as unknown as string,
      time: moment(currentKickoff?.attributes?.time, 'HH:mm SSS') as unknown as string,
      description: currentKickoff?.attributes?.description,
      recipients: currentKickoff?.attributes?.recipients?.map(it => it?.userId) as unknown as InputMaybe<InputMaybe<ComponentDataContactInput>[]>,
      discussionTopics: currentKickoff?.attributes?.discussionTopics?.data?.map(it => it?.id),
    }
  }
  // const onGenerateKickoffLetter = async () => {
  //   console.log('generate', currentKickoff)
  //   const values = await form.validateFields()
  //   await onSaveAsDraft(values, uploadFilesIds, uploadApprovalFilesIds, itemId, appOwner, 'draft')
  // }
  const onConfirm = async () => {
    currentKickoff?.id && await updateRecord({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentKickoff?.id,
        data: {
          status: 'accepted',
        },
      },
      onCompleted: () => {
        BlackNotification('Record has been successfully accepted.')
        setIsModalOpen(false)
      },
      onError: (error) => {
        BlackNotification(`Error occurred. ${error.message}`)
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })
  }
  const handleBaseInviteChange = async (id: string) => {
    setSelectedInviteId(id)
    await fetchInvite({
      fetchPolicy: globalFetchPolicy,
      variables: {id},
      onCompleted: (data) => {
        const invite = data?.kickoffInvite?.data as KickoffInviteEntity
        // form.setFieldValue('date', moment(invite?.attributes?.date, 'YYYY-MM-DD'))
        form.setFieldsValue({
          date:  moment(invite?.attributes?.date, 'YYYY-MM-DD'),
          time: moment(invite?.attributes?.time, 'HH:mm SSS'),
          description: invite?.attributes?.description,
        })
      },
      onError: (error)=>{
        console.log(error.message)
      }
    })
  }
  const locationPoint = {
        'type': 'Feature',
        'geometry': location,
      } as Feature<Point>
  return applicationLoading || currentKickoffLoading || topicsLoading || appOwnerLoading ? (
    <Spin />
  ) : (
    <>
      <FormItemDivider label={'Kickoff record'} marginBottom={0} marginTop={0} />
      <Space direction={'vertical'} style={{ width: '100%', paddingTop: 8 }} size={'middle'}>
        <Text size={'md'}>{`${currentKickoff ? 'Edit' : 'Create'} you kickoff record and submit it for confirmation`}</Text>
        <Form
          layout={'vertical'}
          size={'middle'}
          onFinish={handleSaveAsDraft}
          form={form}
          initialValues={initialValues}

        >
          <FormItem
            name={'kickoffInvite'}
            type={'select'}
            placeholder={'Select base Invite'}
            label={'Base Kickoff Invite'}
            selectOptions={inviteOptions}
            required={false}
            handleSelectChange={handleBaseInviteChange}
          />

          <Row>
            <Col span={24}>
              <MapBoxMap
                mapRef={mapRef as Ref<MapRef>}
                isDrawTools={false}
                isGeometryVisibilityToggle={false}
                mapContainerHeight={300}
                onLoad={onLoad}

              >
                {location && (
                  <Marker
                    key={'center'}
                    longitude={location?.coordinates?.[0]}
                    latitude={location?.coordinates?.[1]}
                    draggable={true}
                    onDrag={onMarkerDrag}
                    style={{opacity: 0}} // to be able to drag marker but MarkerCircle will be rendered
                  />
                )}
                <MarkerCircle locationPoint={locationPoint}/>
              </MapBoxMap>
              {!readOnly && <Text>{'Drag marker if needed to specify the exact place of meeting.'}</Text>}
            </Col>
          </Row>

          <Row gutter={16} style={{paddingTop: 24}}>
            <Col span={12}>
              <FormItem name={'date'} type={'date-picker'} label={'Date of meeting'} readOnly={readOnly} />
            </Col>
            <Col span={12}>
              <FormItem name={'time'} type={'time-picker'} label={'Time of meeting'} readOnly={readOnly} />
            </Col>
          </Row>
          <FormItem
            name={'discussionTopics'}
            type={'multi-select'}
            placeholder={'Select key discussions'}
            label={'Key discussions'}
            selectOptions={topicsOptions}
            readOnly={readOnly}
          />
          <FormItem name={'description'} type={'textarea'} placeholder={'Enter description'} rows={5} label={'Description/ Requirements'} readOnly={readOnly} />
          <FormItem
            name={'recipients'}
            type={'multi-select'}
            placeholder={'Select participants'}
            label={'Recipient'}
            selectOptions={recipientOptions}
          />
          <Row>
            <Col span={24}>
              <Upload
                onChange={info => onUploadChange(info)}
                {...props}
                defaultFileList={[...defaultFileList]}
                className={'kickoff-record-upload'}
                itemRender={(_, file, fileList, actions) => {
                  const { preview, remove, download } = actions
                  return (
                    <UploadFileItem onPreview={preview} file={file} onRemove={remove} onDownload={download} isReadOnly={role === 'client'} />
                  )
                }}
              >
                <Button block btnType={'ghost'} text={'Upload photos'} icon={<SvgIcon type={'download'} />} disabled={role === 'client'} />
              </Upload>
            </Col>
          </Row>


          {/*<FormItemDivider />*/}
          {/*<Button disabled loading={loading} text={'Generate kickoff letter'} btnType={'ghost'} icon={<SvgIcon type={'envelop'}  />} block={true} style={{marginTop: 20}} onClick={onGenerateKickoffLetter}/>*/}
          <FormItemDivider />
          <Upload
            onChange={info => onApprovalUploadChange(info)}
            {...props}
            defaultFileList={[...defaultApprovalFileList]}
            className={'kickoff-record-upload'}
            itemRender={(_, file, fileList, actions) => {
              const { preview, remove, download } = actions
              return (
                <UploadFileItem onPreview={preview} file={file} onRemove={remove} onDownload={download} isReadOnly={role === 'client'} />
              )
            }}
          >
            <Button block btnType={'ghost'} text={'Upload approval documents'} icon={<SvgIcon type={'download'} />} disabled={role === 'client'} />
          </Upload>
          {readOnly ? (
            <Row justify={'end'} style={{ paddingTop: 24 }}>
              <Button text={'Confirm & Accept'} btnType={'primary'} loading={recordUpdating} onClick={onConfirm}
                      disabled={currentKickoff?.attributes?.status === 'accepted'} />
            </Row>
          ) : (
            <Row justify={'end'} style={{ paddingTop: 24 }}>
              <Button
                text={itemId ? 'Update Record' : 'Save as draft'}
                btnType={'text'}
                htmlType={'submit'}
                loading={loading}
                disabled={currentKickoff?.attributes?.status === 'accepted' || currentKickoff?.attributes?.status === 'sent'}
              />
              <Popconfirm
                placement={'topRight'}
                title={<Col style={{ maxWidth: '300px' }}>
                  <Text>{`Record will be submitted to `}</Text><Text
                  weight={'w600'}>{`${appOwner?.attributes?.userProfile?.data?.attributes?.fullName}. `}</Text>
                  <Paragraph style={{margin: 0}}>{`Email with the Record details will be sent to the following email address: `}</Paragraph>
                  <Text weight={'w600'}>{appOwner?.attributes?.email}</Text>
                </Col>}
                onConfirm={onSaveAndSubmit}
                okText={'Send'}
                cancelText={'Cancel'}
                cancelButtonProps={{loading: sendingRecord}}
                okButtonProps={{loading: sendingRecord}}
              >
                <Button
                  text={'Submit record'}
                  btnType={'primary'}
                  loading={loading}
                  // disabled={currentKickoff?.attributes?.status === 'accepted' || currentKickoff?.attributes?.status === 'sent'}
                />
              </Popconfirm>
            </Row>
          )}
        </Form>
      </Space>
      <Modal open={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel} closeIcon={<SvgIcon type={'close-modal'} />}>
        <img alt={'example'} style={{ width: '100%' }} src={previewImage as string} />
      </Modal>
    </>
  )
}
export default CreateKickoffRecord
