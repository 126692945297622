import { dataUrlToFile } from '@/utilities/urlToFile'
import { getAuthToken } from '@/helpers/auth'
import { v4 as uuid } from 'uuid'

interface MapImageGenerator {
  (base64Data: string): Promise<UploadFileFiltersInput[]>;
}

export const mapImageGeneratorFromBase64: MapImageGenerator = async (base64Data) => {
  const token = getAuthToken()

  try {
    const file = await dataUrlToFile(base64Data, 'map-image')
    const form = new FormData()
    const uniqueId = uuid().slice(0, 8)
    form.append('files', file, `snapshot-${uniqueId}.png`)
    const responseUpload = await fetch(
      `${(import.meta.env.WEBSITE_API_URL ?? '').replace('/graphql', '')}/api/upload`,
      {
        headers: { Authorization: `Bearer ${token}` },
        method: 'post',
        body: form,
      },
    )
    return await responseUpload.json()
  } catch (error) {
    console.log(error)
  }
}