import { FC } from "react"
import { useNavigate } from "react-router"

import Button from "../../button/Button"
import { useGeneralContext } from "../../context/GeneralContext"
import Typography from "../../typography/Typography"

const { Title, Paragraph } = Typography

const SimpleModal: FC = () => {
  const { setIsModalOpen, modalSettings } = useGeneralContext()
  const navigate = useNavigate()
  const onConfirm = () => {
    setIsModalOpen(false)
    modalSettings.buttonLink && navigate(modalSettings.buttonLink ? modalSettings?.buttonLink : "/")
  }
  return (
    <>
      <Title level={3}>{modalSettings?.title}</Title>
      <Paragraph style={{ marginTop: 24 }} size={"sm"}>
        {modalSettings?.content}
      </Paragraph>
      <div className='btn-block'>
        <Button btnType={"text"} text={"Cancel"} onClick={() => setIsModalOpen(false)} size={"small"} />
        <Button
          text={modalSettings?.buttonText ? modalSettings.buttonText : "Ok"}
          btnType={"primary"}
          onClick={modalSettings?.onClick ? modalSettings?.onClick : onConfirm}
        />
      </div>
    </>
  )
}
export default SimpleModal
