import { SvgIcon } from '@/components/icon'
import { RoleTypes } from '@/types'
import { EASETECH_COMPANY } from '@/utilities/global-variables'
import { ItemType } from 'antd/lib/menu/hooks/useItems'

const clientMenu: ItemType[] = [
  {
    key: 'overview',
    icon: <SvgIcon type={'sidebar-overview'} />,
    label: 'Overview',
  },
  {
    key: 'projects',
    icon: <SvgIcon type={'sidebar-projects'} />,
    label: 'Projects',
  },
  {
    key: 'applications',
    icon: <SvgIcon type={'sidebar-applications'} />,
    label: 'Applications',
  },
]
const clientOrgMenu: ItemType[] = [
  {
    key: 'overview',
    icon: <SvgIcon type={'sidebar-overview'} />,
    label: 'Overview',
  },
  {
    key: 'applications',
    icon: <SvgIcon type={'sidebar-applications'} />,
    label: 'Applications',
  },
]

const agentMenu: ItemType[] = [
  {
    key: 'overview',
    label: 'Overview',
    icon: <SvgIcon type={'sidebar-compass'} />,
  },
  {
    key: 'projects',
    label: 'Projects',
    icon: <SvgIcon type={'sidebar-projects'} />,
  },
  {
    key: 'applications',
    label: 'Applications',
    icon: <SvgIcon type={'sidebar-applications'} />,
  },
  {
    key: 'customers',
    label: 'Customers',
    icon: <SvgIcon type={'sidebar-bag'} />,
  },
  {
    key: 'tasks',
    label: 'Tasks',
    icon: <SvgIcon type={'sidebar-tickBox'} />,
  },
  {
    key: 'template-groups',
    label: 'Templates',
    icon: <SvgIcon type={'sidebar-barchart'} />,
  },
]
const agentOrgMenu: ItemType[] = [
  {
    key: 'overview',
    label: 'Overview',
    icon: <SvgIcon type={'sidebar-compass'} />,
  },
  {
    key: 'applications',
    label: 'Applications',
    icon: <SvgIcon type={'sidebar-applications'} />,
  },
  {
    key: 'customers',
    label: 'Customers',
    icon: <SvgIcon type={'sidebar-bag'} />,
  },
  {
    key: 'tasks',
    label: 'Tasks',
    icon: <SvgIcon type={'sidebar-tickBox'} />,
  },
  {
    key: 'template-groups',
    label: 'Templates',
    icon: <SvgIcon type={'sidebar-barchart'} />,
  },
]
const managerMenu: ItemType[] = [
  {
    key: 'overview',
    label: 'Overview',
    icon: (<SvgIcon type={'sidebar-compass'} />),
  },
  {
    key: 'companies',
    label: 'Companies',
    icon: <SvgIcon type={'sidebar-companies'} />,
  },
  {
    key: 'manager-map',
    label: 'Map Geometry',
    icon: <SvgIcon type={'manager-map'} />,
  },
  {
    key: 'projects',
    label: 'Projects',
    icon: <SvgIcon type={'sidebar-folder'} />,
  },
  {
    key: 'reports',
    label: 'Applications',
    icon: <SvgIcon type={'manager-reports'} />,
  },
  {
    key: 'agents-professionals',
    label: 'Agents',
    icon: <SvgIcon type={'professionals-list'} />,
  },
  {
    key: 'customers',
    label: 'Customers',
    icon: <SvgIcon type={'sidebar-bag'} />,
  },
  {
    key: 'tasks',
    label: 'Tasks',
    icon: <SvgIcon type={'sidebar-tickBox'} />,
  },
  {
    key: 'template-groups',
    label: 'Templates',
    icon: <SvgIcon type={'sidebar-barchart'} />,
  },
  {
    key: 'platform-settings',
    label: 'Settings',
    icon: <SvgIcon type={'settings'} />,
  },
]
const serviceProviderMenu: ItemType[] = [
  {
    key: 'overview',
    label: 'Overview',
    icon: <SvgIcon type={'sidebar-overview'} />,
  },
  {
    key: 'projects',
    label: 'Projects',
    icon: <SvgIcon type={'sidebar-projects'} />,
  },
  {
    key: 'applications',
    label: 'Applications',
    icon: <SvgIcon type={'sidebar-applications'} />,
  },
  {
    key: 'government-works',
    label: 'Government Works',
    icon: <SvgIcon type={'sidebar-government'} />,
  },
]
export const genMenuItems = (role: RoleTypes, organization: string): ItemType[] => {
  switch (role) {
    case 'client':
      return getClientMenu(organization)
    case 'agent':
      return getAgentMenu(organization)
    case 'manager':
      return managerMenu
    case 'service_provider':
      return serviceProviderMenu
    default:
      return []
  }

}

export const getClientMenu = (organization: string): ItemType[] => {
  switch (organization) {
    case EASETECH_COMPANY:
      return clientMenu
    case 'eskom':
      return clientOrgMenu
    default:
      return clientOrgMenu
  }
}
export const getAgentMenu = (organization: string): ItemType[] => {
  switch (organization) {
    case EASETECH_COMPANY:
      return agentMenu
    case 'eskom':
      return agentOrgMenu
    default:
      return agentOrgMenu
  }
}