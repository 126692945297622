import Button from "../../button/Button"
import { useGeneralContext } from "../../context/GeneralContext"
import Typography from "../../typography/Typography"

const { Text, Paragraph } = Typography
const ApplicationInfo = () => {
  const { setIsModalOpen } = useGeneralContext()
  const onConfirm = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <Text size={"xl"}>{"Application information!"}</Text>
      <Paragraph style={{ marginTop: 24 }} size={"sm"}>
        {"This is just the start, and you have to pay for the application to go further to approve."}
      </Paragraph>
      <div className='btn-block'>
        <Button text={"Cancel"} btnType={"text"} />
        <Button text={"Confirm"} btnType={"primary"} onClick={onConfirm} />
      </div>
    </>
  )
}
export default ApplicationInfo
