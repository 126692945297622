import { useCompanyQuery, useUpdateCompanyMutation, useUserProfilesLazyQuery } from '@/graphql'
import { longDash } from '@/helpers/globalVariables'
import { Form } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem, { SelectOptionsType } from '../../form-item/FormItem'
import Typography from '../../typography/Typography'


const { Title, Paragraph, Text } = Typography
const AssignUser: FC = () => {
  const [searchParams] = useSearchParams()
  const companyId = searchParams.get('id')

  const { setIsModalOpen } = useGeneralContext()
  const [updateCompany] = useUpdateCompanyMutation()

  const [profiles, setProfiles] = useState<UserProfileEntity[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)

  const { data: companyData } = useCompanyQuery({ variables: { id: companyId || '' } })
  const company = companyData?.company?.data as CompanyEntity
  const companyType = company?.attributes?.companyType

  const [fetchProfilesPortion] = useUserProfilesLazyQuery()

  const existingCompanyUsers = company?.attributes?.userProfiles?.data?.map(it => it?.id) || []

  const fetchProfiles = async (page: number) => {
    setLoadingMore(true)

    const result = await fetchProfilesPortion({
      variables: {
        filter: {
          company: {
            id: { eq: null },
          },
        },
        pagination: {
          page: page,
          pageSize: 20,
        },
      },
    })

    const newProfiles = result?.data?.userProfiles?.data as UserProfileEntity[]
    const pageCount = result?.data?.userProfiles?.meta?.pagination?.pageCount || 1

    setProfiles((prev) => [...prev, ...newProfiles])
    setHasMore(page < pageCount)
    setLoadingMore(false)
  }
  const handleScroll = async (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target as HTMLDivElement

    if (scrollHeight - scrollTop === clientHeight && hasMore && !loadingMore) {
      await fetchProfiles(currentPage + 1) // Fetch next page
      setCurrentPage((prev) => prev + 1)
    }
  }
  useEffect(() => {
    fetchProfiles(1).then()
  }, [])

  console.log('render assign user popup')

  const selectOptionsArray = profiles?.map((it) => {
    return {
      label: (
        <>
          <Text weight={'w600'}>{`${it?.attributes?.fullName} | `}</Text>
          <Text>{it?.attributes?.emails?.[0]?.value ?? longDash}</Text>
        </>
      ),
      value: it?.id,
    }
  })


  const onFinish = async (value: Record<string, string>) => {
    companyId && await updateCompany({
      variables: {
        id: companyId,
        data: {
          userProfiles: [value?.user, ...existingCompanyUsers],
        },
      },
      onError: (error) => {
        BlackNotification(error.message)
      },
      onCompleted: () => {
        BlackNotification('Employee assigned to a current company successfully.')
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })
  }

  const onCancel = () => {
    setIsModalOpen(false)
  }

  const initialValues = {
    companyType: [companyType],
  }

  return (
    <>
      <Title level={3}>{'Assign employee to a Company'}</Title>
      <Paragraph style={{ marginTop: 24 }} size={'sm'}>
        {
          'In the list below you can find users not attached to any company'
        }
      </Paragraph>
      <Paragraph size={'sm'} color={'light-gray'}>
        {
          'After assigning employee, please do not forget to select their level of approval in case the current company is Service provider or Government type'
        }
      </Paragraph>
      <Form layout={'vertical'} className={'form-content'} onFinish={onFinish} initialValues={initialValues}>
        <FormItem
          name={'user'}
          type={'select'}
          label={'Employee'}
          placeholder={'Select employee'}
          selectOptions={selectOptionsArray as SelectOptionsType[]}
          loading={loadingMore}
          showSearch={true}
          onPopupScroll={handleScroll}
        />
        <div className={'btn-block'} style={{ justifyContent: 'flex-end' }}>
          <Button text={'Cancel'} btnType={'ghost'} onClick={onCancel} style={{ marginRight: 12 }} />
          <Button text={'Confirm selection'} btnType={'primary'} htmlType={'submit'} />
        </div>
      </Form>
    </>
  )
}
export default AssignUser
