import BlackNotification from '@/components/blackNotification/BlackNotification'
import FormItem from '@/components/form-item/FormItem'
import FormItemSubtitleDivider from '@/components/form-item/FormItemDivider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useCompaniesQuery, useRolesQuery, useUpdateUserMutation, useUserQuery } from '@/graphql'
import { Form, message, Radio, RadioChangeEvent, Spin, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'
import avatarPlaceholder from './images/avatar_user_placeholder.jpg'

const { Title, Text } = Typography
const UserProfileReview = () => {
  const { setIsModalOpen, setTriggerRefetch, currentId } = useGeneralContext()
  const [updateUser] = useUpdateUserMutation()
  const [value, setValue] = useState('pending')
  const [isRoleSelectDisabled, setIsRoleSelectDisabled] = useState(false)

  const { data: companiesData, loading: companiesDataLoading } = useCompaniesQuery({
    fetchPolicy: globalFetchPolicy,
  })
  const companies = companiesData?.companies?.data
  const { data: rolesData, loading: rolesDataLoading } = useRolesQuery({
    fetchPolicy: globalFetchPolicy,
  })
  const allRoles = rolesData?.usersPermissionsRoles?.data as UsersPermissionsRoleEntity[]
  const roles = allRoles?.filter(
    it =>
      it?.attributes?.name !== 'Authenticated' &&
      it?.attributes?.name !== 'Public' &&
      it?.attributes?.name !== 'Manager',
  )
  const { data, loading } = useUserQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })
  const userData = data?.usersPermissionsUser?.data

  useEffect(() => {
    if (!loading && userData?.attributes?.defaultAgent) {
      setIsRoleSelectDisabled(true)
    }
  }, [userData?.attributes?.defaultAgent, loading])

  useEffect(() => {
    if (!loading) {
      setValue(userData?.attributes?.status as string)
    }
  }, [loading, userData?.attributes?.status])

  const onFinish = (formValues: any) => {
    userData?.id && updateUser({
      variables: {
        id: userData?.id,
        data: {
          status: value as InputMaybe<EnumUserspermissionsuserStatus>,
          blocked: value === 'blocked',
          role: formValues?.userRole,
        },
      },
    })
      .then(() => {
        BlackNotification('User status updated')
        setIsModalOpen(false)
        setTriggerRefetch(true)
      })
      .catch(error => {
        message.error('Error creating company', error.message).then()
      })
  }

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }
  const userRoleOptions = roles?.map(it => ({
    label: it?.attributes?.name,
    value: it?.id,
  }))

  const userCompanyOptions = companies?.map(it => ({
    label: `${it?.attributes?.name} (${it?.attributes?.companyType})`,
    value: it?.id,
  }))

  const initialValues = {
    userRole: userData?.attributes?.role?.data?.id,
    userCompany: userData?.attributes?.company?.data?.id,
  }

  return loading || rolesDataLoading || companiesDataLoading ? (
    <Spin />
  ) : (
    <React.Fragment>
      <Title level={3}>{'User profile review'}</Title>
      <FormItemSubtitleDivider marginTop={0} />

      <div className='header-block'>
        <div className='text-section'>
          <Text size={'lg'} weight={'w600'}>
            {userData?.attributes?.userProfile?.data?.attributes?.fullName}
          </Text>
          <Text size={'md'} color={'light-gray'}>
            {userData?.attributes?.company?.data?.attributes?.name}
          </Text>
          <div className='additional-info'>
            <Text size={'sm'}>{`Role: ${userData?.attributes?.role?.data?.attributes?.name}`}</Text>
            <Text size={'sm'}>{`Email: ${userData?.attributes?.userProfile?.data?.attributes?.email}`}</Text>
            <Text size={'sm'}>{`Phone: ${userData?.attributes?.userProfile?.data?.attributes?.phone}`}</Text>
          </div>
        </div>
        <div className='avatar-section'>
          <img
            src={
              userData?.attributes?.userProfile?.data?.attributes?.avatar?.data?.attributes?.url
                ? userData?.attributes?.userProfile?.data?.attributes?.avatar?.data?.attributes?.url
                : avatarPlaceholder
            }
            alt='avatar'
          />
        </div>
      </div>
      <Form layout={'vertical'} className={'form-content'} onFinish={onFinish} initialValues={initialValues}>
        <FormItemSubtitleDivider label={'Adjust user account here:'} marginTop={0} />

        <Radio.Group
          onChange={onChange}
          value={value}
          style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}
        >
          <Radio value={'pending'} style={{ marginBottom: 10 }} className={'user-radio-item'}>
            <div className={'user-radio-item-content'}>
              <Text size={'md'} className={'radio-item-name'}>
                {'Pending'}
              </Text>
              <Tooltip
                overlayClassName={'main-tooltip'}
                placement='bottomRight'
                title={
                  'Default status that indicates that user\'s registration information is needed to be validated by Manager '
                }
              >
                <div className='tool-tip-info'>{'i'}</div>
              </Tooltip>
            </div>
          </Radio>
          <Radio value={'active'} style={{ marginBottom: 10 }} className={'user-radio-item'}>
            <div className={'user-radio-item-content'}>
              <Text size={'md'} className={'radio-item-name'}>
                {'Active'}
              </Text>
              <Tooltip
                overlayClassName={'main-tooltip'}
                placement='bottomRight'
                title={'User allowed to access all the application functionality according their user Role'}
              >
                <div className='tool-tip-info'>{'i'}</div>
              </Tooltip>
            </div>
          </Radio>
          <Radio value={'inactive'} style={{ marginBottom: 10 }} className={'user-radio-item'}>
            <div className={'user-radio-item-content'}>
              <Text size={'md'} className={'radio-item-name'}>
                {'Inactive'}
              </Text>
              <Tooltip
                overlayClassName={'main-tooltip'}
                placement='bottomRight'
                title={'Inactive users can see their dashboards but not allowed to create any content'}
              >
                <div className='tool-tip-info'>{'i'}</div>
              </Tooltip>
            </div>
          </Radio>
          <Radio value={'blocked'} style={{ marginBottom: 10 }} className={'user-radio-item'}>
            <div className={'user-radio-item-content'}>
              <Text size={'md'} className={'radio-item-name'}>
                {'Blocked'}
              </Text>
              <Tooltip
                overlayClassName={'main-tooltip'}
                placement='bottomRight'
                title={'Blocked users are not allowed to log in into platform'}
              >
                <div className='tool-tip-info'>{'i'}</div>
              </Tooltip>
            </div>
          </Radio>
        </Radio.Group>
        {userData?.attributes?.defaultAgent ? (
          <Tooltip
            overlayClassName={'main-tooltip'}
            title={
              'That user has a \'Default Agent status\'. That means all new applications will be automatically allocated to him.'
            }
            placement={'bottomLeft'}
          >
            <div className='text-form-item'>
              {
                <Text size={'md'} weight={'w600'} color={'light-gray'}>
                  {'Default Agent'}
                </Text>
              }
            </div>
          </Tooltip>
        ) : (
          <FormItem
            isDisabled={isRoleSelectDisabled}
            label={'User role:'}
            name={'userRole'}
            type={'select'}
            selectOptions={userRoleOptions}
          />
        )}

        <FormItem label={'User Company:'} name={'userCompany'} type={'select'} selectOptions={userCompanyOptions} />
        <div className='btn-block' style={{ justifyContent: 'flex-end' }}>
          <Button text={'Cancel'} btnType={'ghost'} onClick={onCancel} style={{ marginRight: 12 }} />
          <Button text={'Save changes'} btnType={'primary'} htmlType={'submit'} />
        </div>
      </Form>
    </React.Fragment>
  )
}
export default UserProfileReview
