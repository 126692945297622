import AgentCard from '@/components/agent-card/AgentCard'
import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { Loader } from '@/components/layout/Loader'
import TablePlaceholder from '@/components/table-placeholder/TablePlaceholder'
import { useApplicationQuery, useUpdateApplicationMutation, useUpdateUserMutation, useUserLazyQuery, useUsersQuery } from '@/graphql'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import { Row, Space } from 'antd'
import { FC, useState } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const AgentAssign: FC = () => {
  const { organization } = useAuth()
  const [updateApplication, { loading: applicationUpdating }] = useUpdateApplicationMutation()
  const [updateNewAgent] = useUpdateUserMutation()
  const {data: appDataQuery, loading: appDataLoading} = useApplicationByUuid()
  const currentApplication  = appDataQuery?.applications?.data?.[0] as ApplicationEntity

  const { setIsModalOpen, currentId } = useGeneralContext()

  const [selectedId, setSelectedId] = useState('')


  const existingAgentsIds = currentApplication?.attributes?.agentsInfo?.map(it => it?.agent?.data?.id) || []
  const existAgentsInfo = currentApplication?.attributes?.agentsInfo as ComponentDataAgentInfo[]

  const [fetchAgent] = useUserLazyQuery()

  const { data, loading } = useUsersQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: {
        role: { name: { eq: 'Agent' } },
        organization: {
          slug: { in: ['easetech', organization] },
        },
      },
    },
  })
  const agentUsers = data?.usersPermissionsUsers?.data as UsersPermissionsUserEntity[]
  const availableAgents = agentUsers?.filter(it => !existingAgentsIds?.includes(it.id))

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onConfirm = async () => {
    const existAgentInfoSanitized = existAgentsInfo?.map(it => ({
      agent: it?.agent?.data?.id,
      assignStatus: it?.assignStatus
    }))

    const newAgentsInfoSet = [...existAgentInfoSanitized, {
      agent: selectedId,
    }]
    const result = await fetchAgent({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: selectedId
      }
    })
    const selectedAgent = result?.data?.usersPermissionsUser?.data
    const selectedAgentsCustomersIds = selectedAgent?.attributes?.agentCustomers?.data?.map(it => it?.id) || []

    await updateNewAgent({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: selectedId,
        data: {
          agentCustomers: [...selectedAgentsCustomersIds, currentApplication?.attributes?.owner?.data?.id],
        }
      }
    })
    await updateApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentId,
        data: {
          agentsInfo: newAgentsInfoSet,
        },
      },
      onCompleted: () => {
        BlackNotification('Agent assigned')
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(`Error while trying to assign agent. ${error.message}`)
      },
      refetchQueries: 'active',
    })

  }
  const onCardClick = (id: string) => {
    setSelectedId(id)
  }
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Assign agent to application'}
      </Text>
      {availableAgents?.length !== 0 ? (
        <>
          <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>
            {`All assigned agents will be able to collect approval information and approve application`}
          </Paragraph>
          {loading || appDataLoading ? <Loader /> : (
            <Row>
              <Space direction={'vertical'} style={{ width: '100%' }}>
                {availableAgents?.length !== 0 && availableAgents?.map(it => (
                  <AgentCard
                    key={it?.id} userData={it} isSelected={it?.id === selectedId} onCardClick={onCardClick} isSelectable={true} />
                ))}
              </Space>

            </Row>

          )}

        </>
      ) : (
        <Row justify={'center'}>
            <TablePlaceholder title={'No agents available.'} text={' Please, register new agent or contact out platform support team.'}/>
        </Row>
      )}
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        {availableAgents?.length !== 0 && (
          <>
            <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
            <Button text={'Assign selected agent'} btnType={'primary'} onClick={onConfirm} loading={applicationUpdating} />
          </>
        )}
      </Row>
    </>
  )
}
export default AgentAssign
