import { useProjectsQuery } from '@/graphql'
import { createdAppDataLocalStore } from '@/helpers/globalVariables'
import { websiteRoutes } from '@/router/routes'
import { CreatedApplicationData } from '@/types'
import { Radio, RadioChangeEvent, Spin, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useLocalStorage } from 'react-use'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'

const { Title, Paragraph, Text } = Typography
const SelectedProjectInfo = () => {
  const navigate = useNavigate()
  const { dashboard } = websiteRoutes
  const { setIsModalOpen } = useGeneralContext()
  const [value, setValue] = useState('fullWayleave')
  const [appData, setAppData] = useLocalStorage(createdAppDataLocalStore)
  const createdAppData = appData as CreatedApplicationData
  const { data, loading } = useProjectsQuery({
    variables: { filter: { id: { eq: createdAppData?.projectId } } },
    fetchPolicy: globalFetchPolicy,
  })
  const currentProjectData = data?.projects?.data?.[0]
  const onConfirm = () => {
    setAppData({ ...appData || [], serviceType: value })
    setIsModalOpen(false)
    navigate(`${dashboard.createApplication}/application-details/1`)
  }
  const onReturn = () => {
    navigate(dashboard.projects)
    setIsModalOpen(false)
  }
  const projectName = currentProjectData?.attributes?.title
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  return loading ? (
    <Spin />
  ) : (
    <React.Fragment>
      <Title level={3}>{'Project information:'}</Title>
      <div className={"text-block"} style={{ marginTop: 24 }}>
        <Text size={'sm'}>{'Project '}</Text>
        <Text size={'lg'} weight={'w600'}>{` ${projectName} `}</Text>
        <Text size={'sm'}>{'has been selected. Your applications generated will be linked to this project.'}</Text>
      </div>
      <Paragraph style={{ marginTop: 24 }} size={'sm'}>
        {`You will now be directed to complete your application and enter the location details of your works. Our system will analyse this information and detect affected parties, infrastructure and services. `}
      </Paragraph>
      <Paragraph style={{ marginTop: 24 }} size={'sm'}>
        {`A quote will then be processed for your review and approval – there will be zero obligation until you accept our quote.`}
      </Paragraph>
      {/*TODO: radio group is temporary hidden (till 2 dev iteration)*/}
      {/*<Radio.Group onChange={onChange} value={value} style={{ paddingTop: 20 }}>*/}
      {/*  <Tooltip*/}
      {/*    overlayClassName={'main-tooltip'}*/}
      {/*    placement='bottomLeft'*/}
      {/*    title={*/}
      {/*      'Full wayleave service means that ...some explanation'*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <Radio value={'fullWayleave'} style={{ marginBottom: 10 }}>*/}
      {/*      {'Full wayleave service'}*/}
      {/*    </Radio>*/}
      {/*  </Tooltip>*/}
      {/*  <Tooltip*/}
      {/*    overlayClassName={'main-tooltip'}*/}
      {/*    placement='bottomLeft'*/}
      {/*    open={false} //temporary disabled*/}
      {/*    title={*/}
      {/*      'Third party service only ...some explanation'*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <Radio value={'thirdPartyOnly'} style={{ marginBottom: 10 }} disabled>*/}
      {/*      <Text size={'sm'} color={'light-gray'}>*/}
      {/*        {'Third party service only'}</Text>*/}
      {/*    </Radio>*/}
      {/*  </Tooltip>*/}
      {/*  <Tooltip*/}
      {/*    overlayClassName={'main-tooltip'}*/}
      {/*    placement='bottomLeft'*/}
      {/*    open={false} //temporary disabled*/}
      {/*    title={*/}
      {/*      'Municipal or ser gov only ...some explanation'*/}
      {/*    }*/}
      {/*  >*/}
      {/*    <Radio value={'municipalOnly'} disabled>*/}
      {/*      <Text size={'sm'} color={'light-gray'}>{*/}
      {/*        'Municipal or ser gov only'}</Text>*/}
      {/*    </Radio>*/}
      {/*  </Tooltip>*/}
      {/*</Radio.Group>*/}
      <div className={"btn-block"}>
        <Button text={'Go to Projects page'} btnType={'text'} onClick={onReturn} />
        <Button text={'Continue'} btnType={'primary'} onClick={onConfirm} />
      </div>
    </React.Fragment>
  )
}
export default SelectedProjectInfo
