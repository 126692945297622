import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import TagItemsGenerator from '@/components/tag-items-generator/TagItemsGenerator'
import UserAvatar from '@/components/user-avatar/UserAvatar'
import { useCreateUserProfileMutation } from '@/graphql'
import { removeUnderscores } from '@/helpers/removeUnderscores'
import useCompanyOptions from '@/hooks/useCompaniesOptions'
import { levelOfApprovalOptions } from '@/pages/company/content/employees/LevelOfApprovalSelect'
import { Form, Row, Space } from 'antd'
import { BaseSyntheticEvent, Dispatch, FC, SetStateAction, useState } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem from '../../form-item/FormItem'
import Typography from '../../typography/Typography'


const responsibilities: EnumUserprofileResponsibility[] = ['administrator', 'site_representative', 'external_service_provider', 'contractor', 'engineer']
const responsibilityOptions = responsibilities?.map(it => ({
  label: removeUnderscores(it),
  value: it,
}))
const { Title } = Typography
type Props = {
  onCancel: () => void
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}
const CreateUserProfile: FC<Props> = ({onCancel, setIsModalOpen}) => {
  const { companyOptions, loading: companiesDataLoading } = useCompanyOptions('all')


  const { currentId } = useGeneralContext()
  const [createUserProfile, {loading}] = useCreateUserProfileMutation()

  const [fullName, setFullName] = useState('')
  const [emails, setEmails] = useState<string[]>([])
  const [phones, setPhones] = useState<string[]>([])
  const [addresses, setAddresses] = useState<string[]>([])

  const onFinish = async (value: Record<string, string>) => {

    const savingEmails: InputMaybe<ComponentDataTextFieldInput>[] = emails?.map(it => ({
      value: it,
    }))
    const savingPhones: InputMaybe<ComponentDataTextFieldInput>[] = phones?.map(it => ({
      value: it,
    }))
    const savingAddresses: InputMaybe<ComponentDataTextFieldInput>[] = addresses?.map(it => ({
      value: it,
    }))

    await createUserProfile({
      fetchPolicy: globalFetchPolicy,
      variables: {
        input: {
          company: value?.company,
          fullName: value?.fullName,
          emails: savingEmails,
          phones: savingPhones,
          address: savingAddresses,
          responsibility: value.responsibilities as InputMaybe<EnumUserprofileResponsibility>,
        },
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(error.message)
        setIsModalOpen(false)
      },
      onCompleted: () => {
        BlackNotification('User profile created successfully')
        setIsModalOpen(false)
      },
      refetchQueries: 'active'
    })
  }

  // const onCancel = () => {
  //   setIsModalOpen(false)
  // }
  const onFullNameChange = (e: BaseSyntheticEvent) => {
    setFullName(e.target.value)
  }
  const hasSpace = /^[^\s].*\s+.*[^\s]$/.test(fullName)
  const initialValues = {
    fullName: 'User Name',
    company: currentId,
    responsibilities: 'contractor',
    levelOfApproval: 'firstAndOnly'
  }
  const handleCreateEmails = (tags: string[]) => {
    setEmails(tags)
  }
  const handleCreatePhones = (tags: string[]) => {
    setPhones(tags)
  }
  const handleCreateAddress = (tags: string[]) => {
    setAddresses(tags)
  }
  return (
    <>
      <Row align={'middle'} style={{ marginBottom: 20 }}>
        <Space size={'middle'} align={'center'}>
          <UserAvatar userFullName={hasSpace ? fullName : 'User Name'} />
          <Title level={3} style={{ marginBottom: 0 }}>{'Create user profile'}</Title>
        </Space>
      </Row>
      <Form layout={'vertical'} className={'form-content'} onFinish={onFinish} initialValues={initialValues}>
        <FormItem
          onInput={onFullNameChange}
          label={'Full name'}
          name={'fullName'}
          type={'input'}
          placeholder={'Enter full name'}
        />
        <FormItem
          name={'company'}
          label={'Company'}
          type={'select'}
          selectOptions={companyOptions}
          placeholder={'Select Company'}
          loading={companiesDataLoading}
          showSearch={true}
        />
        <FormItem
          name={'levelOfApproval'}
          label={'Level of Approval'}
          type={'select'}
          selectOptions={levelOfApprovalOptions}
          placeholder={'Select level of approval'}
        />
        <FormItem label={'Responsibilities type'} name={'responsibilities'} type={'select'} selectOptions={responsibilityOptions} />
        <TagItemsGenerator label={'Email'} buttonText={'Add email'} handleCreateTag={handleCreateEmails} />
        <TagItemsGenerator label={'Phone'} buttonText={'Add phone number'} handleCreateTag={handleCreatePhones} />
        <TagItemsGenerator label={'Address'} buttonText={'Add address'} handleCreateTag={handleCreateAddress} />

        <div className={'btn-block'} style={{ justifyContent: 'flex-end' }}>
          <Button text={'Cancel'} btnType={'ghost'} onClick={onCancel} style={{ marginRight: 12 }} />
          <Button text={'Create'} btnType={'primary'} htmlType={'submit'} loading={loading} />
        </div>
      </Form>
    </>
  )
}
export default CreateUserProfile
