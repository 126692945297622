import Typography from '@/components/typography/Typography'
import { Spin } from 'antd'
import cn from 'classnames'
import React, { FC } from 'react'

const { Paragraph } = Typography
type Props = {
  isVisible?: boolean,
}
const IsSavingMapCover: FC<Props> = ({ isVisible = false }) => {
  return (
    <div className={cn('loading-pop-up', { visible: isVisible })}>
      <Paragraph weight={'w500'} size={'md'}
                 color={'white'}>{'A large amount of data is being fetched and analyzed, this may take a while...'}</Paragraph>
      <Spin />
    </div>

  )
}

export default IsSavingMapCover
