import { useAuth } from '@/components/context/AuthProvider'
import { checkApplicationDocuments } from '@/utilities/applicationChecking'
import { message } from 'antd'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useApplicationsQuery,
  useCreateNotificationMessageMutation,
  useUpdateApplicationMutation,
  useUpdateInvoiceMutation,
} from '@/graphql'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'

const { Text, Paragraph } = Typography
const InvoiceApprove = () => {
  const { user } = useAuth()
  const { setIsModalOpen, currentId, setTriggerRefetch } = useGeneralContext()
  const [searchParams] = useSearchParams()
  const currentAppUuid = searchParams?.get('id') as string
  const [updateInvoice] = useUpdateInvoiceMutation()
  const [updateApplication] = useUpdateApplicationMutation()
  const [createNotification] = useCreateNotificationMessageMutation()

  const { data: appDataResponse, loading } = useApplicationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { filter: { uuid: { eq: currentAppUuid } } },
  })
  const appData = appDataResponse?.applications?.data?.[0] as ApplicationEntity

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onApprove = async () => {
    const checkDocuments = checkApplicationDocuments(appData?.attributes)
    try {
      await updateInvoice({
        variables: {
          id: currentId,
          data: {
            status: 'paid',
          },
        },
        onError: (error) => {
          message.error(error.message)
        },
        fetchPolicy: globalFetchPolicy,
        refetchQueries: 'active',
      })
      appData?.id && await updateApplication({
        variables: {
          id: appData?.id,
          data: {
            status: checkDocuments ? 'ready_for_submitting' : 'outstanding_documents',
            progress: checkDocuments ? 30 : 15
          },
        },
        fetchPolicy: globalFetchPolicy,
        refetchQueries: 'all',
        onError: (error) => {
          message.error(error.message)
        },
      })
      await createNotification({
        variables: {
          input: {
            application: appData?.id,
            from: user?.id,
            to: appData?.attributes?.owner?.data?.id,
            message: `Payment for application ${appData?.attributes?.title} has been confirmed. Please proceed with verifying your application details and submitting.`,
          },
        },
        fetchPolicy: globalFetchPolicy,
        onError: (error) => {
          message.error(error.message)
        },
      })

      BlackNotification('Payment approved successfully.')
      setIsModalOpen(false)
      setTriggerRefetch(true)
    } catch (e) {
      message.error('Error updating payment data')
    }
  }
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Invoice payment approval'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w600'}>
        {'You are about to approve payment for this applications invoice.'}
      </Paragraph>
      <Paragraph style={{ marginTop: 20 }} size={'sm'}>
        {'Please ensure that the payment documents uploaded are correct and that the amounts reflect in our bank account before approving'}
      </Paragraph>
      <div className='btn-block'>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Approve'} btnType={'primary'} onClick={onApprove} loading={loading} />
      </div>
    </>
  )
}
export default InvoiceApprove
