import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useApplicationQuery, useUpdateApplicationMutation } from '@/graphql'
import { approvalsProgressCalculator } from '@/helpers/approvalsProgressCalculator'
import { longDash } from '@/helpers/globalVariables'
import { checkIsReadyForApproval } from '@/helpers/isReadyForApprovalChecker'
import useApprovalEnvelopCopy from '@/hooks/useApprovalEnvelopCopy'
import { message, Row } from 'antd'
import { FC } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Title, Paragraph, Text } = Typography
const DeleteApprovalItem: FC = () => {
  const { setIsModalOpen, currentId, currentApprovalItemId } = useGeneralContext()
  const { data, loading: appDataLoading } = useApplicationQuery({
    variables: { id: currentId },
    fetchPolicy: globalFetchPolicy,
  })
  const currentApplication = data?.application?.data as ApplicationEntity
  const approvalEnvelop = currentApplication?.attributes?.approvalEnvelop as ComponentDataApplicationServiceProvider[]
  const [updateApplication, { loading }] = useUpdateApplicationMutation()
  const onCancel = () => {
    setIsModalOpen(false)
  }

  const filteredApprovalEnvelop = approvalEnvelop?.filter(it => it?.id !== currentApprovalItemId)
  const deletedItem = approvalEnvelop?.find(it => it?.id === currentApprovalItemId)
  const newApprovalEnvelop = useApprovalEnvelopCopy(filteredApprovalEnvelop)
  const onDelete = async () => {
    const newProgress = approvalsProgressCalculator(filteredApprovalEnvelop as ComponentDataApplicationServiceProviderInput[])

    await updateApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentId,
        data: {
          progress: newProgress,
          approvalEnvelop: newApprovalEnvelop,
        },
      },
      refetchQueries: 'active',
      onError: (error) => {
        message.error(error.message)
      },
      onCompleted: (result) => {
        BlackNotification('Approval item deleted successfully')
        const updateApprovalEnv = result?.updateApplication?.data?.attributes?.approvalEnvelop
        const checkResult = updateApprovalEnv && checkIsReadyForApproval(updateApprovalEnv as ComponentDataApplicationServiceProviderInput[])
        if (checkResult) {
          BlackNotification(
            'Application ready for approval. Press \'Approve application\' button to approve the application. You will not be allowed to undo this action.',
          )
        }
        setIsModalOpen(false)
      },
    })
  }
  return (
    <>
      <Title level={3}>
        {'Delete Approval Item'}
      </Title>
      <Paragraph style={{ margin: '32px 0 0', padding: 0 }} size={'sm'} weight={'w400'}>
        {`You are going to delete approval item for the company: `}
      </Paragraph>
        <Text weight={'w700'} size={'sm'}>{`${deletedItem?.companyName?? longDash}.`}</Text>
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Delete'} btnType={'primary'} onClick={onDelete} loading={loading || appDataLoading} />
      </Row>
    </>
  )
}
export default DeleteApprovalItem
