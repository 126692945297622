import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { message } from 'antd'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useApplicationsQuery,
  useCreateInvoiceMutation,
  useCreatePaymentMutation,
  useUpdateApplicationMutation,
  useUpdateQuotationMutation,
} from '../../../graphql'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const ConfirmQuotation = () => {
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('id')

  const { setIsModalOpen, setCurrentId } = useGeneralContext()
  const [createInvoice] = useCreateInvoiceMutation()
  const [updateQuotation] = useUpdateQuotationMutation()
  const [updateApplication] = useUpdateApplicationMutation()

  const [isLoading, setIsLoading] = useState(false)

  const { data, loading } = useApplicationsQuery({
    variables: { filter: { uuid: { eq: uuid } } },
    fetchPolicy: globalFetchPolicy,
  })
  const currentAppData = data?.applications?.data?.[0]
  const currentQuotationId = currentAppData?.attributes?.quotation?.data?.id
  const currentAppId = currentAppData?.id

  const onConfirm = async () => {
    setIsLoading(true)
    currentQuotationId && await updateQuotation({
      variables: {
        id: currentQuotationId,
        data: {
          status: 'confirmed',
        },
      },
      fetchPolicy: globalFetchPolicy,
      refetchQueries: 'active',
      onError: (error) => {
        message.error(`create quotation error: ${error.message}`)
        setIsLoading(false)
      },
    })
    const createdInvoice = await createInvoice({
      variables: {
        input: {
          status: 'created',
          application: currentAppId,
        },
      },
      fetchPolicy: globalFetchPolicy,
      refetchQueries: 'active',
      onError: (error) => {
        message.error(`create invoice error: ${error.message}`)
        setIsLoading(false)
      },
    })
    if (createdInvoice?.data?.createInvoice?.data?.id) {
      currentAppId && await updateApplication({
        variables: {
          id: currentAppId,
          data: {
            invoice: createdInvoice?.data?.createInvoice?.data?.id,
            status: 'invoice_created',
            progress: 15,
          },
        },
        fetchPolicy: globalFetchPolicy,
        refetchQueries: 'active',
        onError: (error) => {
          message.error(`update app: ${error.message}`)
          setIsLoading(false)
        },
        onCompleted: () => {
          setIsLoading(false)
          setIsModalOpen(false)
          BlackNotification('Invoice created')
        },
      })
      setCurrentId(createdInvoice?.data?.createInvoice?.data?.id as string)
    }
  }
  const onCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Accept Quotation'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w600'}>
        {'Once you accept this quote an invoice will automatically be generated and accessible to you for download. Payment can then be processed at your convenience.'}
      </Paragraph>
      <Paragraph style={{ marginTop: 24 }} size={'sm'}>
        {'Please note that payment is required before we submit your applications.'}
      </Paragraph>
      <div className='btn-block'>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Confirm'} btnType={'primary'} onClick={onConfirm} loading={isLoading || loading} />
      </div>
    </>
  )
}
export default ConfirmQuotation
