import { Feature, Point } from 'geojson'
import React, { FC } from 'react'
import { Layer, Source } from 'react-map-gl'

type Props = {
  index?: string
  locationPoint: Feature<Point>
}
const PindropIcon:FC<Props> = ({index, locationPoint}) => {
  return (
    <Source
      key={'marker-symbol' + index}
      id={'marker-symbol' + index}
      type={'geojson'}
      data={locationPoint}
    >
      <Layer
        id={'marker-symbol' + index}
        type={'symbol'}
        layout={{
          'icon-image': 'custom-marker-icon', // matches the ID of the image added to the map on Load function
          'icon-size': 0.05,
          'icon-anchor': 'bottom',
          'icon-rotate': 180,
          'icon-allow-overlap': false,
        }}
      />
    </Source>
  )
}

export default PindropIcon
