import { useAuth } from '@/components/context/AuthProvider'
import useOrganizationData from '@/hooks/useOrganizationData'
import { defaultPrimaryColor, EASETECH_COMPANY } from '@/utilities/global-variables'
import { Tooltip } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import classNames from 'classnames'
import React, { useState } from 'react'
import './Sidebar.less'
import Logo from './logo/Logo'
import SiderMenu from './sider-menu/SiderMenu'
import Avatar from './avatar/Avatar'

const Sidebar = () => {
  const {user, organization} = useAuth()
  const [collapsed, setCollapsed] = useState(false)
const {organizationData, loading} = useOrganizationData()

  const classes = classNames({
    'sider-content': true,
    'collapsed': collapsed,
  })

  const handleToggle = () => setCollapsed(!collapsed)
  return (
    <Sider
      collapsedWidth={100}
      width={180}
      className={'dashboard-sider'}
      collapsed={collapsed}
      theme={'light'}
      style={{backgroundColor: organization === EASETECH_COMPANY ? defaultPrimaryColor :  organizationData?.attributes?.primaryColor || defaultPrimaryColor}}
    >
      <div className={classes}>
        <div>
          <Logo onClick={handleToggle} collapsed={collapsed} />
          <SiderMenu collapsed={collapsed} />
        </div>
        <Avatar collapsed={collapsed} />
      </div>

    </Sider>
  )
}

export default Sidebar
