import { useAuth } from '@/components/context/AuthProvider'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { Form, message } from 'antd'
import { useSearchParams } from 'react-router-dom'

import { useApplicationsQuery, useCreateNotificationMessageMutation } from '@/graphql'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem from '../../form-item/FormItem'
import Typography from '../../typography/Typography'

const { Title } = Typography
const RequestInfo = () => {
  const [form] = Form.useForm()
  const { user } = useAuth()
  const myId = user?.id
  const [searchParams] = useSearchParams()
  const uuId = searchParams.get('id')
  const { setIsModalOpen, currentApplicationId } = useGeneralContext()
  const [createNotification] = useCreateNotificationMessageMutation()
  const currentAppId = uuId || currentApplicationId
  const { data } = useApplicationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { filter: { uuid: { eq: currentAppId } } },
  })
  const currentAppData = data?.applications?.data?.[0]
  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onFinish = async (value: { description: string; requestType: InputMaybe<EnumNotificationmessageRequesttype> }) => {
    await createNotification({
      fetchPolicy: globalFetchPolicy,
      refetchQueries: 'active',
      variables: {
        input: {
          application: currentAppData?.id,
          from: myId,
          to: currentAppData?.attributes?.owner?.data?.id,
          message: value.description,
          type: 'request',
          requestType: value.requestType,
        },
      },
      onCompleted: () => {
        form.resetFields()
        setIsModalOpen(false)
        BlackNotification('Request created successfully')
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  }
  const options = [
    {
      label: 'Outstanding documents',
      value: 'documents_issue',
    },
    {
      label: 'Licence issue',
      value: 'licence_issue',
    },
    {
      label: 'Payment issue',
      value: 'payment_issue',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ]

  return (
    <>
      <Title level={2}>{'Request info'}</Title>
      <Form form={form} onFinish={onFinish} autoComplete={'off'} layout={'vertical'} style={{ marginTop: 40 }}>
        <FormItem
          type={'select'}
          name={'requestType'}
          label={'Request reason'}
          selectOptions={options}
          placeholder={'Select request reason'}
        />
        <FormItem type={'textarea'} name={'description'} label={'Description'} placeholder={'Enter Description'} />
        <div className='btn-block'>
          <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
          <Button text={'Send request'} btnType={'primary'} htmlType={'submit'} />
        </div>
      </Form>
    </>
  )
}
export default RequestInfo
