import FormItemDivider from '@/components/form-item/FormItemDivider'
import InfoTextItem from '@/components/info-text-item/InfoTextItem'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import ContactCard from '@/components/notifications/ContactCard'
import TablePlaceholder from '@/components/table-placeholder/TablePlaceholder'
import UserContactCard from '@/components/user-contact-card/UserContactCard'
import { useCompanyQuery } from '@/graphql'
import { longDash } from '@/helpers/globalVariables'
import { removeUnderscores } from '@/helpers/removeUnderscores'
import { Row, Space } from 'antd'
import { FC } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Title, Paragraph } = Typography
const CompanyInfo: FC = () => {
  const { setIsModalOpen, currentId } = useGeneralContext()

  const { data, loading } = useCompanyQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })
  const company = data?.company?.data as CompanyEntity
  const employees = company?.attributes?.users?.data as UsersPermissionsUser[]

  const onCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Title level={3} style={{marginBottom: 0}}>
        {company?.attributes?.name}
      </Title>
      <Paragraph color={'light-gray'} style={{}}>{removeUnderscores(company?.attributes?.companyType||'')}</Paragraph>

      <FormItemDivider label={'Contact info'} />
      <InfoTextItem label={'Address'} text={company?.attributes?.address} />
      <InfoTextItem label={'Email'} text={company?.attributes?.companyEmail} />
      <InfoTextItem label={'Address'} text={company?.attributes?.phone} />
      <FormItemDivider label={'Employees'} />
      <Space direction={'vertical'} style={{width:'100%'}}>
        {employees?.length ? employees?.map(it => <UserContactCard key={it?.id} user={it}/>): <TablePlaceholder imageSize={'small'} title={''} text={'No employees in current company'} />}
      </Space>
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button
          text={'Close'}
          btnType={'primary'}
          onClick={onCancel}
          loading={loading}
        />
      </Row>
    </>
  )
}
export default CompanyInfo
