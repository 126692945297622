import BlackNotification from '@/components/blackNotification/BlackNotification'
import Button from '@/components/button/Button'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useAreaQuery, useUpdateAreaMutation, useUpdateCompanyMutation } from '@/graphql'
import { Form, Row, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import moment from 'moment'
import { FC, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem from '../../form-item/FormItem'
import Typography from '../../typography/Typography'

const statusOptions = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Outdated',
    value: 'outdated',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
]
const { Title, Paragraph } = Typography
const ChangeGeometryStatus: FC = () => {
  const [form] = useForm()
  const [searchParams] = useSearchParams()
  const currentCompanyId = searchParams.get('id')

  const {setTriggerRefetch, setIsModalOpen, currentId, setModalTopOffset, setModalWidth } = useGeneralContext()
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  const [updateArea, { loading: areaUpdating }] = useUpdateAreaMutation()
  const [updateCompany, {loading: companyUpdating}] = useUpdateCompanyMutation()

  const { data, loading } = useAreaQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })
  const currentArea = data?.area?.data as AreaEntity

  const initialValues = {
    status: currentArea?.attributes?.status,
  }
  const onSelectChange = () => {
    setIsSaveDisabled(false)
  }

  const onSave = async (values: { status: EnumAreaStatus }) => {
    await updateArea({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentId,
        data: {
          status: values.status,
          statusUpdated: moment().format('YYYY-MM-DD'),
        },
      },
      onError: (error) => {
        console.log(error)
      },
      onCompleted: () => {
        BlackNotification('Geometry status updated successfully.')
        setIsModalOpen(false)
        setModalTopOffset(null)
        setModalWidth(null)
        setTriggerRefetch(true)
      },
      refetchQueries: 'active',
    })
    currentCompanyId && await updateCompany({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentCompanyId,
        data: {
          geometryLastUpdate: moment().format('YYYY-MM-DD'),
        }
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(error.message)
      }
    })
  }

  return (
    <>
      <Title level={3}>{'Change status'}</Title>
      <Paragraph style={{ marginTop: 24 }} size={'sm'}>
        {'Geometry status shows how long ago the geometry was loaded and whether it will be taken into account when creating an application.'}
      </Paragraph>
      {loading ? (
        <Spin />
      ) : (
        <Form form={form} layout={'vertical'} autoComplete={'off'} initialValues={initialValues} onFinish={onSave}>
          <FormItem name={'status'} type={'select'} label={'Status'} selectOptions={statusOptions} handleSelectChange={onSelectChange} />
          <Row justify={'end'}>
            <Button text={'Cancel'} btnType={'text'} />
            <Button text={'Save changes'} loading={areaUpdating} disabled={isSaveDisabled} htmlType={'submit'}/>
          </Row>
        </Form>
      )}
    </>
  )
}
export default ChangeGeometryStatus
