import { useAuth } from '@/components/context/AuthProvider'
import { useOrganizationQuery } from '@/graphql'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'

const useOrganizationData = (): {loading: boolean, organizationData: OrganizationEntity} => {
  const {user} = useAuth()
  const organizationId = user?.organization?.data?.id || '';
  const {data, loading} = useOrganizationQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {id: organizationId}
  })
  const organizationData = data?.organization?.data as OrganizationEntity

  return {organizationData, loading}
}
export default useOrganizationData
