import "./ModalContent.less"

const ApplicationAssignStep01 = () => {
  return (
    <div className={"create-project-modal"}>
      <h1>{"Temp"}</h1>
    </div>
  )
}
export default ApplicationAssignStep01
