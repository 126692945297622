import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useAuth } from '@/components/context/AuthProvider'
import { getMapSnapshot } from '@/components/google-map/helpers/getMapSnapshot'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { StatusType } from '@/components/status/Status'
import { useCompaniesLazyQuery, useCreateKickoffInviteMutation, useUpdateInviteMutation } from '@/graphql'
import { Point } from 'geojson'
import moment from 'moment'
import { MutableRefObject, useCallback, useState } from 'react'
import { MapRef } from 'react-map-gl'

interface UseSaveAsDraftProps {
  mapRef: MutableRefObject<MapRef | undefined>
  appData: ApplicationEntity;
  location: Point | null;
}

const useSaveAsDraft = ({ mapRef, appData, location }: UseSaveAsDraftProps) => {
  const {user} = useAuth()
  const [loading, setLoading] = useState(false);
  const [fetchCompanies] = useCompaniesLazyQuery()
  const [createInvite] = useCreateKickoffInviteMutation()
  const [updateInvite] = useUpdateInviteMutation()

  const onSaveAsDraft = useCallback(
    async (values: KickoffInviteInput, itemId: Maybe<string>, clientData: Maybe<UsersPermissionsUserEntity>, status: InputMaybe<EnumKickoffinviteStatus>) => {
      try {
        setLoading(true);
        const companiesIds = values?.participants as string[];
        const { data } = await fetchCompanies({
          fetchPolicy: globalFetchPolicy,
          variables: {
            filter: {
              id: { in: companiesIds },
            },
          },
        });
        const companies = data?.companies?.data as CompanyEntity[];
        const mapSnapshot = await getMapSnapshot(mapRef) as unknown as (UploadFileInput & { id: string })[];

        const participants: InputMaybe<Array<InputMaybe<ComponentDataContactInput>>> = companies?.map(it => ({
          companyId: it.id,
          companyName: it.attributes?.name,
          email: it.attributes?.companyEmail,
        }));

        const clientParticipant: InputMaybe<Array<InputMaybe<ComponentDataContactInput>>> = [{
          companyId: clientData?.attributes?.company?.data?.id,
          companyName: clientData?.attributes?.company?.data?.attributes?.name,
          email: clientData?.attributes?.email,
          name: clientData?.attributes?.userProfile?.data?.attributes?.fullName,
          phone: clientData?.attributes?.userProfile?.data?.attributes?.phone,
          userId: clientData?.id
        }]

        if(itemId){
          const result = await updateInvite({
            fetchPolicy: globalFetchPolicy,
            variables: {
              id: itemId,
              data: {
                date: moment(values?.date).format('YYYY-MM-DD'),
                time: moment(values?.time).format('HH:mm:ss.SSS'),
                description: values?.description,
                inviteRequiredDocumentTypes: values?.inviteRequiredDocumentTypes,
                status,
                application: appData?.id,
                location,
                participants: clientData ? clientParticipant : participants,
                mapSnapshot: mapSnapshot?.[0]?.id,
              },
            },
            refetchQueries: 'active',
            onCompleted: () => {
              BlackNotification('Invite updated successfully');
            },
            onError: error => {
              BlackNotification(`Invite update failed. ${error.message}`);
            },
          })
          return result?.data?.updateKickoffInvite?.data?.id
        } else {
          const result = await createInvite({
            fetchPolicy: globalFetchPolicy,
            variables: {
              input: {
                date: moment(values?.date).format('YYYY-MM-DD'),
                time: moment(values?.time).format('HH:mm:ss.SSS'),
                description: values?.description,
                inviteRequiredDocumentTypes: values?.inviteRequiredDocumentTypes,
                status,
                application: appData?.id,
                location,
                participants: clientData ? clientParticipant : participants,
                mapSnapshot: mapSnapshot?.[0]?.id,
                owner: user?.id
              },
            },
            refetchQueries: 'active',
            onCompleted: () => {
              BlackNotification('Invite created');
            },
            onError: error => {
              BlackNotification(`Invite creation failed. ${error.message}`);
            },
          })
          return result?.data?.createKickoffInvite?.data?.id
        }
      } catch (error) {
        console.error('Error saving as draft:', error);
        return null
      } finally {
        setLoading(false);
      }
    },
    [fetchCompanies, mapRef, updateInvite, appData?.id, location, createInvite, user?.id]
  );

  return { onSaveAsDraft, loading };
};

export default useSaveAsDraft;
