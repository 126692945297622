import { Form, message } from 'antd'
import { useState } from 'react'
import { useCreateQuotationStripeItemMutation } from '../../../graphql'
import BlackNotification from '../../blackNotification/BlackNotification'

import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem from '../../form-item/FormItem'
import Typography from '../../typography/Typography'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'

const { Title } = Typography

const CreateQuotationItem = () => {
  const { setIsModalOpen, setTriggerRefetch } = useGeneralContext()
  const [createQuotationStripeItem] = useCreateQuotationStripeItemMutation()
  const [isLoading, setIsLoading] = useState(false)

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onFinish = async (values: { name: string, description: string, price: string }) => {
    setIsLoading(true)
    await createQuotationStripeItem({
      variables: {
        input: {
          name: values.name,
          description: values.description,
          price: values.price,
        },
      },
      fetchPolicy: globalFetchPolicy,
      refetchQueries: 'active',
      onError: (error) => {
        message.error(error.message)
        setIsLoading(false)
      },
      onCompleted: () => {
        BlackNotification('Quotation item created')
        setTriggerRefetch(true)
        setIsModalOpen(false)
        setIsLoading(false)
      },
    })
  }
  return (
    <Form layout={'vertical'} className={'create-quotation-item'} onFinish={onFinish}>
      <Title level={3}>{'Create quotation item'}</Title>
      <FormItem name={'name'} type={'input'} label={'Item name'} />
      <FormItem name={'description'} type={'input'} label={'Item description'} />
      <FormItem name={'price'} type={'input'} label={'Item price (R)'} />
      <div className='btn-block'>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Create'} btnType={'primary'} htmlType={'submit'} loading={isLoading} />
      </div>
    </Form>
  )
}
export default CreateQuotationItem
