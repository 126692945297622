import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { Loader } from '@/components/layout/Loader'
import { Row, message } from 'antd'
import { useState } from 'react'
import { useApplicationQuery, useDeleteApplicationMutation, useDeleteKickoffRecordMutation, useDeleteProjectMutation } from '@/graphql'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const DeleteKickoffRecord = () => {
  const { setIsModalOpen, currentId } = useGeneralContext()

  const [deleteRecord, { loading }] = useDeleteKickoffRecordMutation()
  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onDelete = async () => {
    await deleteRecord({
      variables: {
        id: currentId,
      },
      fetchPolicy: globalFetchPolicy,
      refetchQueries: 'active',
      onError: (error) => {
       BlackNotification(`Error deleting record. ${error.message}`)
      },
      onCompleted: () => {
        BlackNotification('Record deleted')
        setIsModalOpen(false)
      },
    })
  }
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Deleting kickoff record'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>
        {`You are going to delete current kickoff record`}
      </Paragraph>
      <Row style={{paddingTop: 30}} justify={'end'}>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Delete'} btnType={'primary'} onClick={onDelete} loading={loading} />
      </Row>
    </>
  )
}
export default DeleteKickoffRecord
