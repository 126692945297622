import { useAuth } from '@/components/context/AuthProvider'

export type ResultType = {
  allowedToApprove: boolean
  isFirstApprove: boolean
  isLastApprove: boolean
  isFinalApprove: boolean
  nextSignatory: string | null | undefined
}

export const checkApproval = (
  userCompany: CompanyEntity,
  approvalEnvelop: ComponentDataApplicationServiceProvider[],
) => {
  const { user } = useAuth()
  const result: ResultType = {
    allowedToApprove: false,
    isFirstApprove: false,
    isLastApprove: false,
    nextSignatory: null,
    isFinalApprove: false,
  }

  approvalEnvelop?.find(it => it?.companyId === userCompany?.id)
  const userLevelOfApproval = user?.levelOfApproval
  const restApprovalEnvelopItems = approvalEnvelop?.filter(it => it?.companyId !== userCompany?.id)
  const companyEmployee = userCompany?.attributes?.users?.data

  const allApprovalArray = approvalEnvelop?.map(it => it?.approved)
  if (!allApprovalArray?.some(it => it === true) && approvalEnvelop?.length > 1) {
    result.isFirstApprove = true
  }

  const restApprovals = restApprovalEnvelopItems?.map(it => it?.approved)
  if (!restApprovals?.some(it => it === false)) {
    result.isFinalApprove = true
  }

  if (userLevelOfApproval === 'firstAndOnly') {
    result.allowedToApprove = true
    result.isLastApprove = true
  } else if (userLevelOfApproval === 'first' && companyEmployee?.length === 1) {
    result.allowedToApprove = true
    result.isLastApprove = true
  } else if (userLevelOfApproval === 'first' && companyEmployee?.length && companyEmployee?.length >= 1) {
    const nextSignatoryUser = companyEmployee?.find(
      it => it?.attributes?.levelOfApproval === 'second',
    ) as UsersPermissionsUserEntity
    result.nextSignatory = nextSignatoryUser?.attributes?.userProfile?.data?.attributes?.fullName
  } else if (userLevelOfApproval === 'second' && companyEmployee?.length) {
    const nextSignatoryUser = companyEmployee?.find(it => it?.attributes?.levelOfApproval === 'third')
    const nextSignatoryName = nextSignatoryUser?.attributes?.userProfile?.data?.attributes?.fullName

    result.nextSignatory = nextSignatoryName
    if (!nextSignatoryUser) {
      result.nextSignatory = null
      result.isLastApprove = true
      result.allowedToApprove = true
    }
  } else if (userLevelOfApproval === 'second' && companyEmployee?.length === 2) {
    result.allowedToApprove = true
    result.isLastApprove = true
  } else if (userLevelOfApproval === 'third') {
    result.allowedToApprove = true
    result.isLastApprove = true
  }
  return result
}
