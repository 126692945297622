import React, { FC } from "react"
import Typography from "../typography/Typography"

const { Text } = Typography
const NotFoundContent: FC<{ text?: string }> = ({ text }) => {
  return (
    <div>
      <Text>{text ? text : "No data"}</Text>
    </div>
  )
}

export default NotFoundContent
