import { Col, Row } from 'antd'
import React, { FC } from 'react'
import Typography from '../typography/Typography'

const { Text } = Typography
type Props = {
  name: Maybe<string>
  email: Maybe<string>
}
const MultiSelectUserItem:FC<Props> = ({name, email}) => {
  return (
    <Row>
      <Col span={24}>
        <Text weight={'w600'}>{name}</Text>
      </Col>
      <Col span={24}>
        <Text size={'xs'} style={{ fontWeight: 300, lineHeight: '100%' }}>{email}</Text>
      </Col>
    </Row>
  )
}

export default MultiSelectUserItem
