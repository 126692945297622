import { createdAppDataLocalStore } from '@/helpers/globalVariables'
import { Radio, RadioChangeEvent, Tooltip } from 'antd'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useLocalStorage } from 'react-use'
import { useProjectsQuery } from '../../../graphql'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import EscapeButton from '../../escape-button/EscapeButton'
import { SelectOptionsType } from '../../form-item/FormItem'
import { SvgIcon } from '../../icon'
import { Spinner } from '../../spinner/Spinner'
import Typography from '../../typography/Typography'
import { websiteRoutes } from '@/router/routes'

const GeneratedApplications = () => {
  const { dashboard } = websiteRoutes
  const navigate = useNavigate()
  const { setIsModalOpen } = useGeneralContext()
  const [appData, setAppData] = useLocalStorage(createdAppDataLocalStore)

  const loading = false
  const onConfirm = () => {
    setAppData({ ...appData })
    setIsModalOpen(false)
    navigate(`${dashboard.createApplication}/application-details/1`)
  }

  return loading ? (
    <Spinner />
  ) : (
    <div className={'generated-app-modal-content'}>
      <div className='modal-header'>
        <div className='buttons-block'>
          <Button text={''} btnType={'icon'} icon={<SvgIcon type={'arrow-back'} />} />
          <Button text={''} btnType={'icon'} icon={<SvgIcon type={'arrow-back'} />} />
        </div>
        <EscapeButton />
      </div>

      <div className='btn-block'>
        <Button text={'Cancel'} btnType={'text'} />
        <Button text={'Confirm'} btnType={'primary'} onClick={onConfirm} />
      </div>
    </div>
  )
}
export default GeneratedApplications
