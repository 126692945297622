import { FillLayer, LineLayer } from 'react-map-gl'


export const uploadedLayerProps: LineLayer = {
  id: 'uploaded-geometry',
  type: 'line',
  paint: {
    'line-width': 2,
    'line-color': '#ff2d11',
  },
}
export const lineLayerProps: LineLayer = {
  id: 'saved-geometry',
  type: 'line',
  paint: {
    'line-width': 2,
    'line-color': '#005296',
  },
}
export const fillLayerProps: FillLayer = {
  id: 'saved-geometry',
  type: 'fill',
  paint: {
    'fill-color': '#005296',
    'fill-outline-color': '#005296',
    'fill-opacity': 0.2,
  },
}