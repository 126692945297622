import Button from "../../button/Button"
import { useGeneralContext } from "../../context/GeneralContext"
import Typography from "../../typography/Typography"

const { Text, Paragraph } = Typography
const MapSavingInfo = () => {
  const { setIsModalOpen } = useGeneralContext()
  const onConfirm = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <Text size={"xl"}>{"The map is not saved!"}</Text>
      <Paragraph style={{ marginTop: 24 }} size={"sm"}>
        {"To continue, please save your project region on the map."}
      </Paragraph>
      <div className='btn-block'>
        <Button text={"Cancel"} btnType={"text"} />
        <Button text={"Confirm"} btnType={"primary"} onClick={onConfirm} />
      </div>
    </>
  )
}
export default MapSavingInfo
