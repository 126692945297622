import './Header.less'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useOrganizationsQuery } from '@/graphql'
import { getAuthToken } from '@/helpers/auth'
import { sleep } from '@/helpers/sleeper'
import { websiteRoutes } from '@/router/routes'
import { defaultPrimaryColor, EASETECH_COMPANY } from '@/utilities/global-variables'

import React, { FC } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useLocalStorage } from 'react-use'

import Button from '../../../../components/button/Button'
import { SvgIcon } from '../../../../components/icon'
import { Menu, MenuProps, Skeleton } from 'antd'
import MenuItem from 'antd/es/menu/MenuItem'
import { MenuItemsType } from '@/components/double-dots-menu/DoubleDotsMenu'
import WebsiteButton from '@/components/website-button/WebsiteButton'
import { scrollToSection } from '@/helpers/scrollToSection'
import websiteTypography from '@/components/websiteTypography/WebsiteTypography'

const { Text } = websiteTypography

const items = [
  {
    label: 'about',
    key: 'about',
  },
  {
    label: 'our services',
    key: 'our-services',
  },
  {
    label: 'contact',
    key: 'contact',
  },
]
const Header:FC = () => {
  const navigate = useNavigate()
  const { website, dashboard } = websiteRoutes
  const params = useParams()
  const currentOrgSlug = params.org || EASETECH_COMPANY

  const {data, loading} = useOrganizationsQuery({
    fetchPolicy: globalFetchPolicy,
    variables: {
      filter: {slug: {eq: currentOrgSlug}}
    }
  })
  const currentOrganization = data?.organizations?.data?.[0]

  const onLogoClick = () => {
    navigate(`/${currentOrgSlug}${website.home}`)
  }
  const onClick: MenuProps['onClick'] = async (e) => {
    if (e.key === 'about') {
      navigate(`/${currentOrgSlug}${website.home}`)
      await sleep(200)
      scrollToSection('about')
    }
    if (e.key === 'contact') {
      navigate(`/${currentOrgSlug}${website.home}`)
      await sleep(200)
      scrollToSection('contact')
    }
    if (e.key === 'our-services') {
      navigate(`/${currentOrgSlug}${website.home}`)
      await sleep(200)
      scrollToSection('services')
    }
  }
  const onGoToDashboard = () => {
    navigate(dashboard.overview)
  }


  return (
    <div className={'main-header'} style={{backgroundColor: currentOrganization?.attributes?.primaryColor || defaultPrimaryColor}}>
      <div className={'container'}>
        <div className={'header-content'}>
          <div className={'header-logo-wrapper'} onClick={onLogoClick}>
            {/*<SvgIcon type={'logo-easetech-white'} />*/}
            {loading ? <Skeleton loading={true} paragraph={{rows: 1}} /> : (
             <img src={currentOrganization?.attributes?.logoWithCompanyName?.data?.attributes?.url} alt={'logo'} />
            ) }
          </div>
          <div className={"header-content-wrapper"}>
            {currentOrgSlug === EASETECH_COMPANY && (
              <Menu items={items} mode={'horizontal'} className={'header-menu'} onClick={onClick} />
            )}
            {!getAuthToken() ? (
              <div className={'btn-block'}>
                <Link to={`/${currentOrgSlug}${website.auth}/login`}>
                  <WebsiteButton btnType={'ghost-white'} style={{ marginRight: 8 }}>
                    <Text>{'SIGN IN'}</Text>
                  </WebsiteButton>
                </Link>
                <Link to={`/${currentOrgSlug}${website.auth}/client`}>
                  <WebsiteButton>
                    <Text>{'REGISTER'}</Text></WebsiteButton>
                </Link>
              </div>
            ) : (
              <WebsiteButton onClick={onGoToDashboard}>{'go to dashboard'}</WebsiteButton>
            )}
          </div>

        </div>
      </div>
    </div>
  )
}

export default Header
