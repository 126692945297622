import { fillLayerProps, lineLayerProps } from '@/components/google-map/helpers/lineLayersProps'
import { FC } from 'react'
import { Layer, Source } from 'react-map-gl'

type Props = {
  provinces: ProvinceEntity[]
  selectedProvincesIds: string[]
}
const ProvincesSelection:FC<Props> = ({provinces, selectedProvincesIds}) => {
  return (
    <>
      {
        provinces?.map(it => {
          if (it?.id && selectedProvincesIds?.includes(it?.id)) {
            return (
              <Source
                key={it?.id + 'fill'}
                id={it?.id as string}
                type={'geojson'}
                data={it?.attributes?.geoJson?.data?.attributes?.url}

              >
                <Layer
                  {...lineLayerProps}
                  id={it?.id as string}
                  paint={{
                    'line-color': '#1b6021',
                    'line-width': 2,
                  }}
                />
                <Layer
                  {...fillLayerProps}
                  id={it?.id + 'fill' as string}
                  paint={{
                    'fill-color': '#309a28',
                    'fill-outline-color': '#309a28',
                    'fill-opacity': 0.2,
                  }}
                />
              </Source>
            )
          } else {
            return (
              <Source
                key={it?.id}
                id={it?.id as string}
                type={'geojson'}
                data={it?.attributes?.geoJson?.data?.attributes?.url}

              >
                <Layer
                  {...lineLayerProps}
                  id={it?.id as string}
                  paint={{
                    'line-width': 1,
                    'line-color': '#4d7e60',
                  }}
                />
                <Layer
                  {...fillLayerProps}
                  id={it?.id + 'fill' as string}
                  paint={{
                    'fill-color': '#567948',
                    'fill-outline-color': '#567948',
                    'fill-opacity': 0.1,
                  }}
                />
              </Source>
            )
          }

        })
      }

    </>
  )
}

export default ProvincesSelection
