import {  polygonToLine } from '@turf/turf'
import { lineChunkPointsGenerator } from '@/components/google-map/turf-functions/lineChunkPointsGenerator'
import { Feature, FeatureCollection, Geometry, GeometryCollection, LineString, Point, Polygon } from 'geojson'

export const chunkProject = (featureCollection: FeatureCollection<Geometry | GeometryCollection> | null, chunkSize: number) => {

  const singlePoints = featureCollection?.features?.filter((it) => {
    const currentFeature = it as unknown as Feature
    return currentFeature?.geometry?.type === 'Point'
  }) as unknown as Feature<Point>[]
  const singleLines = featureCollection?.features?.filter((it) => {
    const currentFeature = it as unknown as Feature
    return currentFeature?.geometry?.type === 'LineString'
  }) as unknown as Feature<LineString>[]
  const singlePolygons = featureCollection?.features?.filter((it) => {
    const currentFeature = it as unknown as Feature
    return currentFeature?.geometry?.type === 'Polygon'
  }) as unknown as Feature<Polygon>[]

  const geometryCollections = featureCollection?.features?.filter((it) => {
    const currentFeature = it as unknown as Feature
    return currentFeature?.geometry?.type === 'GeometryCollection'
  }) as Feature<GeometryCollection>[]

  let featuresArrayFromCollection = [] as Feature[]
  if (geometryCollections?.length) {
    featuresArrayFromCollection = geometryCollections.flatMap(geoCollectionItem =>
      geoCollectionItem.geometry?.geometries?.map(item => {
        const { type, coordinates } = item as Point | LineString | Polygon;
        return {
          type: 'Feature',
          geometry: {
            type,
            coordinates,
          },
        } as Feature;
      }) || []
    );
  }

  const pointsItems = featuresArrayFromCollection?.filter(it => {
    return it?.geometry?.type === 'Point'
  })
  const lineItems = featuresArrayFromCollection?.filter(it => {
    return it?.geometry?.type === 'LineString'
  })
  const polygonItems = featuresArrayFromCollection?.filter(it => {
    return it?.geometry?.type === 'Polygon'
  })

  const totalPoints = [...singlePoints, ...pointsItems]
  const totalLines = [...singleLines, ...lineItems]
  const totalPolygons = [...singlePolygons, ...polygonItems]


  const linesFromPolygons = totalPolygons?.map(it => {
    const currentIt = it as unknown as Feature<Polygon>
    return polygonToLine(currentIt)
  }) as unknown as Feature<LineString>[]


  let chunkedLinesPolygons: Feature[] = []
  const linesArray = [...totalLines, ...linesFromPolygons]

  linesArray?.forEach(it => {
    const currentFeature = it as unknown as Feature
    const chunkedLine = lineChunkPointsGenerator(currentFeature, chunkSize) as unknown as Feature<Point>[]
    chunkedLinesPolygons = [...chunkedLinesPolygons, ...chunkedLine]
  })

  return [...totalPoints, ...chunkedLinesPolygons]
}