import { Feature, Point } from 'geojson'
import React, { FC } from 'react'
import { Layer, Source } from 'react-map-gl'

type Props = {
  index?: string
  locationPoint: Feature<Point>
}
const PindropCircle:FC<Props> = ({index, locationPoint}) => {
  return (
    <Source
      key={'marker-circle' + index}
      id={'marker-circle' + index}
      type={'geojson'}
      data={locationPoint}
    >
      <Layer
        id={'marker-circle' + index}
        type={'circle'}
        paint={{
          'circle-radius': 5,
          'circle-color': 'red',
          'circle-stroke-color': '#ff3838',
          'circle-stroke-width': 2,
          'circle-opacity': 0.3,
        }}
      />
    </Source>
  )
}

export default PindropCircle
