import { CoordinatesType } from './types'

export type MapInitialSettings = {
  latitude: number,
  longitude: number
  zoom: number
}

export const defaultCenter: CoordinatesType = { lng: 25, lat: -29 }
export const defaultZoom = 6
export const mapInitialViewSettings: MapInitialSettings = {
  latitude: -29,
  longitude: 25,
  zoom: 5,
}

export const minZoom = 2
export const maxZoom = 22
export const maxBounds = [[11, -36], [37, -18]]