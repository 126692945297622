import { ErrorBoundary } from '@/components/app/ErrorBoundary'
import Button from '@/components/button/Button'
import { useAuth } from '@/components/context/AuthProvider'
import TablePlaceholder from '@/components/table-placeholder/TablePlaceholder'
import { setAuthToken } from '@/helpers/auth'
import { websiteRoutes } from '@/router/routes'
import { Col, Row, Space } from 'antd'
import { FC } from 'react'
import { useNavigate } from 'react-router'

const ErrorPage: FC = () => {
  const { organization } = useAuth()
  const navigate = useNavigate()
  const { website } = websiteRoutes
  const onReload = () => {
    window.location.reload()
  }
  const onHomePage = () => {
    navigate(`/${organization}${website.home}`)
    localStorage.clear()
    setAuthToken('')
  }
  return (
    <ErrorBoundary>
      <Row style={{ paddingTop: 100 }} justify={'center'}>
        <Col span={12}>
          <Row justify={'center'}>
            <Col span={12}>
              <TablePlaceholder title={'Something went wrong'}
                                text={'An unexpected error has occurred. We are sorry for the disruption. Please reload the page by clicking the button below.'} />
            </Col>
          </Row>
          <Row justify={'center'}>
            <Space>
              <Button btnType={'ghost'} text={'Go to Home page'} onClick={onHomePage} />
              <Button text={'Reload the page'} onClick={onReload} />
            </Space>
          </Row>
        </Col>
      </Row>
    </ErrorBoundary>
  )
}

export default ErrorPage
