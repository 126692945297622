import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { message, Row } from 'antd'
import { FC, useState } from 'react'
import { useDeleteProjectMutation } from '@/graphql'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const DeleteProject:FC = () => {
  const { setIsModalOpen, currentId, setTriggerRefetch } = useGeneralContext()
  const [deleteProject] = useDeleteProjectMutation()

  const [isLoading, setIsLoading] = useState(false)

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onDelete = async () => {
    setIsLoading(true)
    await deleteProject({
      variables: {
        id: currentId,
      },
      fetchPolicy: globalFetchPolicy,
      refetchQueries: 'active',
      onError: (error) => {
        message.error(error.message)
        setIsLoading(false)
      },
      onCompleted: () => {
        BlackNotification('Project deleted')
        setIsModalOpen(false)
        setTriggerRefetch(true)
        setIsLoading(false)
      },
    })
  }
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Deleting project'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w600'}>
        {'You are going to delete current project.'}
      </Paragraph>
      <Paragraph style={{ margin: "20px 0 30px" }} size={'sm'}>
        {
          'Please, keep in mind that deleting the current project will trigger deleting all applications inside the project.'
        }
      </Paragraph>
      <Row justify={'end'}>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Delete'} btnType={'primary'} onClick={onDelete} loading={isLoading} />
      </Row>
    </>
  )
}
export default DeleteProject
