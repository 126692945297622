import classnames from 'classnames'
import React, { FC } from "react"
import Typography from "../typography/Typography"
import welcomeImage from "./images/welcome.png"
import "./TablePlaceholder.less"

const { Text } = Typography
type Props = {
  title?: string
  text?: string
  imageSize?: 'small' | 'medium' | 'large'
}
const TablePlaceholder: FC<Props> = ({ title, text, imageSize = 'large' }) => {
  const imageClasses = classnames({
    "img-wrapper": true,
    "small-image": imageSize === "small",
    "medium-image": imageSize === "medium",
    "large-image": imageSize === "large",
  })
  return (
    <div className={'welcome-picture-container'}>
      <div className={'welcome-picture-wrapper'}>
        <div className={imageClasses}>
          <img src={welcomeImage} alt={'image'} />
        </div>
        <Text size={"md"} weight={"w600"} style={{ display: "block", marginBottom: 8 }} centered>
          {title ? title : ""}
        </Text>
        <Text color={"gray"} centered>
          {text ? text : ""}
        </Text>
      </div>
    </div>
  )
}

export default TablePlaceholder
