import "./ModalContent.less"

import { AutoComplete, Form, FormInstance, Input, message, Tag } from "antd"
import moment from "moment"
import React, { createRef, Ref, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import { useApplicationsQuery, useUpdateProjectMutation } from "../../../graphql"
import Button from "../../button/Button"
import { useGeneralContext } from "../../context/GeneralContext"
import { SvgIcon } from "../../icon"
import { Spinner } from "../../spinner/Spinner"
import Typography from "../../typography/Typography"

const { Paragraph } = Typography
type OptionType = {
  value: string | null | undefined
}
const ApplicationAssignStep02 = () => {
  const formRef: Ref<FormInstance<Record<string, string>>> | undefined = createRef()
  const [form] = Form.useForm()
  const [updateProject] = useUpdateProjectMutation()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const [isSaveDisabled, setIsSaveDisabled] = useState(true)
  const [options, setOptions] = useState<OptionType[]>([])
  const [appsArray, setAppsArray] = useState<string[]>([])
  const [appsIds, setAppsIds] = useState<string[] | undefined>([])

  const { setIsModalOpen, setModalType } = useGeneralContext()
  const { data, loading } = useApplicationsQuery({ variables: {} })
  const appsData = data?.applications?.data

  useEffect(() => {
    if (appsData) {
      const options: OptionType[] = appsData?.map(it => ({
        value: it?.attributes?.title,
      }))
      setOptions(options)
    }
  }, [appsData])

  const onFinish = () => {
    if (id) {
      updateProject({
        variables: {
          id: id,
          data: {
            applications: appsIds,
          },
        },
      })
        .then(() => {
          setModalType("project-created-success")
        })
        .catch(e => {
          message.error(e?.message ? e.message : "Something went wrong...").then()
        })
    }
  }
  const onCancel = () => {
    formRef?.current?.resetFields()
  }

  const onSelect = (value: string) => {
    if (!value) {
      setIsSaveDisabled(true)
    } else {
      setIsSaveDisabled(false)
    }
    setAppsArray(prev => {
      return [...prev, value]
    })
    setAppsIds(prev => {
      const currentApp = appsData?.find(it => it?.attributes?.title === value)
      if (prev && currentApp && currentApp?.id) return [...prev, currentApp?.id]
    })
    const newOptions = options.filter(it => it?.value !== value)
    setOptions(newOptions)
  }
  const handleClose = (removedTag: string) => {
    const newTags = appsArray?.filter(tag => tag !== removedTag)
    setAppsArray(newTags)

    setAppsIds(prev => {
      const currentApp = appsData?.find(it => it?.attributes?.title === removedTag)
      if (prev && currentApp && currentApp?.id) return prev.filter(it => it !== currentApp?.id)
    })
    setOptions(prev => [...prev, { value: removedTag }])
  }
  return loading ? (
    <Spinner />
  ) : (
    <>
      <Paragraph size={"xl"} style={{ marginBottom: 24 }}>
        {"Assign Application"}
      </Paragraph>
      <Form onFinish={onFinish} autoComplete={"off"} layout={"vertical"} form={form} ref={formRef}>
        <Form.Item label={"Application"} name={"application"}>
          <AutoComplete
            allowClear={true}
            autoClearSearchValue={true}
            style={{ width: "100%" }}
            options={options}
            placeholder='Enter Number of Application'
            onSelect={onSelect}
            suffixIcon={<SvgIcon type={"magnifying-glass"} />}
            filterOption={(inputValue, option) => {
              return option?.value?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }}
          />
        </Form.Item>
        <div className='applications-grid'>
          {appsArray.map((it, index) => (
            // <ApplicationTag key={index} onApplicationClose={onApplicationClose} />
            <Tag key={index} closable={true} onClose={() => handleClose(it)}>
              {it as string}
            </Tag>
          ))}
        </div>
        <div className='btn-block'>
          <Button text={"Cancel"} btnType={"text"} onClick={onCancel} />
          <Button disabled={isSaveDisabled} text={"Save"} btnType={"primary"} htmlType={"submit"} />
        </div>
      </Form>
    </>
  )
}
export default ApplicationAssignStep02
