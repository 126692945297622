import { MutableRefObject } from 'react'
import { MapRef } from 'react-map-gl'
import { mapImageGeneratorFromBase64 } from '@/components/google-map/helpers/mapImageGeneratorFromBase64'
import { sleep } from '@/helpers/sleeper'

export const getMapSnapshot = async (mapRef: MutableRefObject<MapRef | undefined>): Promise<UploadFileFiltersInput[] | undefined> => {
  const map = mapRef?.current?.getMap()

  map?.setBearing(map?.getBearing())

  const base64mapImage = await new Promise<string>((resolve) => {
    map?.once('render', function(e) {
      const base64Image = e.target.getCanvas().toDataURL();
      resolve(base64Image); // Resolve the promise with the base64 string
    });
  });
  return await mapImageGeneratorFromBase64(base64mapImage)
}

export const getMapSnapshotBase64string = async (mapRef: MutableRefObject<MapRef | undefined>): Promise<string | undefined> => {
  let mapImage
  const map = mapRef?.current?.getMap()
  /* trigger render */
  map?.setBearing(map?.getBearing())
  map?.once('render', async function(e) {
    mapImage = e.target.getCanvas().toDataURL()
  })
  await sleep(1000)
  return mapImage
}