import BlackNotification from '@/components/blackNotification/BlackNotification'
import FormItem from '@/components/form-item/FormItem'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useCompanyLazyQuery, useUpdateApplicationMutation } from '@/graphql'
import { approvalsProgressCalculator } from '@/helpers/approvalsProgressCalculator'
import { checkIsReadyForApproval } from '@/helpers/isReadyForApprovalChecker'
import useApplicationByUuid from '@/hooks/useApplicationByUuid'
import useApprovalEnvelopCopy from '@/hooks/useApprovalEnvelopCopy'
import useCompanyOptions from '@/hooks/useCompaniesOptions'
import { Form, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { FC, useState } from 'react'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Title, Paragraph } = Typography
const AddApprovalItem: FC = () => {
  const [form] = useForm()

  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true)
  const [updateApplication, { loading: applicationUpdating }] = useUpdateApplicationMutation()
  const { data: appDataQuery, loading: appDataLoading } = useApplicationByUuid()
  const appData = appDataQuery?.applications?.data?.[0] as ApplicationEntity
  const approvalEnvelop = appData?.attributes?.approvalEnvelop as ComponentDataApplicationServiceProvider[]
  const approvalEnvelopCopy = useApprovalEnvelopCopy(approvalEnvelop)
  const { setIsModalOpen } = useGeneralContext()
  const { companyOptions, loading: companiesDataLoading } = useCompanyOptions('approvals')

  const [fetchCompany, { loading: fetchingCompany }] = useCompanyLazyQuery()

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onConfirm = async () => {
    form.submit()
  }
  const handleSelectChange = () => {
    setIsConfirmDisabled(false)
  }
  const onFinish = async (values: ApplicationInput) => {
    const company = await fetchCompany({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: values?.company || '',
      },
    })
    const companyInfo = company?.data?.company?.data as CompanyEntity

    const newApprovalItem = {
      companyId: values?.company,
      companyName: companyInfo?.attributes?.name,
      companyType: companyInfo?.attributes?.companyType,
    } as ComponentDataApplicationServiceProviderInput
    const newApprovalEnvelop = [newApprovalItem, ...approvalEnvelopCopy || []]
    const newProgress = approvalsProgressCalculator(newApprovalEnvelop)

    appData?.id && await updateApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: appData?.id,
        data: {
          approvalEnvelop: newApprovalEnvelop,
          progress: newProgress,
        },
      },
      onCompleted: (result) => {
        BlackNotification('New approval item created')
        const updateApprovalEnv = result?.updateApplication?.data?.attributes?.approvalEnvelop as ComponentDataApplicationServiceProvider[]

        const checkResult = checkIsReadyForApproval(updateApprovalEnv)
        if (checkResult) {
          BlackNotification(
            'Application ready for approval. Press \'Approve application\' button to approve the application. You will not be allowed to undo this action.',
          )
        }
        setIsModalOpen(false)
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(`Error while trying to create approval item. ${error.message}`)
      },
      refetchQueries: 'active',
    })
  }

  return (
    <>
      <Title level={3}>
        {'Add approval item'}
      </Title>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>
        {`Please, select a Company in the form below to add new approval item to a current application.`}
      </Paragraph>
      <Form layout={'vertical'} onFinish={onFinish} form={form}>
        <FormItem
          name={'company'}
          label={'Company'}
          type={'select'}
          selectOptions={companyOptions}
          placeholder={'Select Company'}
          loading={companiesDataLoading}
          showSearch={true}
          handleSelectChange={handleSelectChange}
        />
      </Form>
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button
          text={'Cancel'}
          btnType={'text'}
          onClick={onCancel}
        />
        <Button
          text={'Confirm'}
          btnType={'primary'}
          onClick={onConfirm}
          loading={applicationUpdating || fetchingCompany || appDataLoading}
          disabled={isConfirmDisabled}
        />
      </Row>
    </>
  )
}
export default AddApprovalItem
