import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useCreateProjectEmailNotificationInputMutation } from '@/graphql'
import { createdAppDataLocalStore } from '@/helpers/globalVariables'
import { CreatedApplicationData } from '@/types'
import { message } from 'antd'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { useLocalStorage } from 'react-use'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'
import { websiteRoutes } from '@/router/routes'

const { Text, Paragraph } = Typography
const CreateApplication:FC = () => {
  const { dashboard } = websiteRoutes
  const navigate = useNavigate()
  const { setTriggerRefetch, setIsModalOpen } = useGeneralContext()
  const [createAppDataLocal] = useLocalStorage(createdAppDataLocalStore)
  //===> Getting data from local storage:
  const createAppData = createAppDataLocal as CreatedApplicationData

  const [createProjectEmailSend, { loading }] = useCreateProjectEmailNotificationInputMutation()
  const onConfirm = async () => {
    if(createAppData?.projectId) {
      await createProjectEmailSend({
        fetchPolicy: globalFetchPolicy,
        variables: {
          input: { projectId: createAppData?.projectId },
        },
        onCompleted: () => {
          setIsModalOpen(false)
          setTriggerRefetch(true)
          navigate(dashboard.splitApplications)
        },
        onError: (error) => {
          message.error(error.message)
        },
      })
    } else {
      setIsModalOpen(false)
      navigate(dashboard.splitApplications)
    }
  }
  const onCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Create application and request quote'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'lg'}>
        {'An application will be created based on the information provided and a quote generated for your review and approval before your application/s are confirmed and submitted.'}
      </Paragraph>
      <Paragraph style={{ marginTop: 24 }} size={'sm'}>
        {'Our system will now analyse your project location and determine the infrastructure and parties affected by your proposed works. Separate applications may be created if multiple infrastructure are affected - these will be displayed for your review and confirmation before any further steps are taken.'}
      </Paragraph>
      <Paragraph weight={'w600'} style={{ margin: '24px 0px 80px' }} size={'sm'}>
        {'Our agents will be available to guide and assist you with any questions.'}
      </Paragraph>
      <div className={'btn-block'}>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Create'} btnType={'primary'} onClick={onConfirm} loading={loading} />
      </div>
    </>
  )
}
export default CreateApplication
