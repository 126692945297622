export const checkApplicationDocuments = (applicationData: Maybe<Application>): boolean => {
  const checkArray: boolean[] = []
  applicationData?.requiredDocuments?.forEach(it => {
    if (it?.documents?.data?.length) {
      checkArray.push(true)
    } else {
      checkArray.push(false)
    }
  })
  return !checkArray.includes(false)
}
export const checkApplicationPayments = (applicationsData: Maybe<Application>) => {
  const appInvoice = applicationsData?.invoice?.data as InvoiceEntity
  return (appInvoice && appInvoice?.attributes?.status === 'paid') || applicationsData?.isPaymentSkipped
}
