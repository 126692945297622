import BlackNotification from '@/components/blackNotification/BlackNotification'
import Button from '@/components/button/Button'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useApplicationQuery, useDocumentTypesQuery, useUpdateApplicationMutation } from '@/graphql'
import { DocumentTypeEntity } from '@/pages/company/content/documentation/PannelHeader'
import { Form, Row, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { FC, useState } from 'react'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem from '../../form-item/FormItem'
import Typography from '../../typography/Typography'

type DocOption = {
  docTypeName: Maybe<string>
  description: Maybe<string>
  documents?: Maybe<string>[] | undefined
}
const { Title, Paragraph } = Typography
const AddRequiredDocument: FC = () => {
  const [form] = useForm()

  const { setIsModalOpen, currentId } = useGeneralContext()
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)


  const [updateApplication, { loading: areaUpdating }] = useUpdateApplicationMutation()

  const { data: currentAppData } = useApplicationQuery({
    fetchPolicy: globalFetchPolicy,
    variables: { id: currentId },
  })

  const { data, loading } = useDocumentTypesQuery({
    fetchPolicy: globalFetchPolicy,
  })
  const currentApplication = currentAppData?.application?.data

  const documentTypes = data?.documentTypes?.data as DocumentTypeEntity[]
  const currentRequiredDocSet = currentApplication?.attributes?.requiredDocuments as ComponentDataRequiredDocuments[]
  const existingDocsPluralized: DocOption[] = currentRequiredDocSet?.map(it => ({
    docTypeName: it?.docTypeName,
    description: it?.description,
    documents: it?.documents?.data?.map(it => it?.id),
  }))
  const documentsOptions = documentTypes?.filter(it => {
    const existingDocTypesTitles = currentApplication?.attributes?.requiredDocuments?.map(item => item?.docTypeName) || []
    return !existingDocTypesTitles.includes(it?.attributes?.title)
  }).map(it => ({
    label: it?.attributes?.title,
    value: it?.id,
  }))
  const onSelectChange = () => {
    setIsSaveDisabled(false)
  }

  const onSave = async (values: { docTypeId: string }) => {
    const selectedDocumentEntity = documentTypes?.find(it => it?.id === values.docTypeId)
    const selectedOption: DocOption = {
      docTypeName: selectedDocumentEntity?.attributes?.title,
      description: selectedDocumentEntity?.attributes?.requirements,
    }


    await updateApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentId,
        data: {
          requiredDocuments: [...existingDocsPluralized, selectedOption],
        },
      },
      onError: (error) => {
        console.log(error)
        BlackNotification(error.message)
      },
      onCompleted: () => {
        BlackNotification('Document type added successfully.')
        setIsModalOpen(false)
      },
      refetchQueries: 'active',
    })
  }
  const onCancel = () => {
    setIsModalOpen(false)
  }
  return (
    <>
      <Title level={3}>{'Add document type'}</Title>
      <Paragraph style={{ marginTop: 24 }} size={'sm'}>
        {'Document type required for application approval process.'}
      </Paragraph>
      {loading ? (
        <Spin />
      ) : (
        <Form form={form} layout={'vertical'} autoComplete={'off'} onFinish={onSave}>
          <FormItem name={'docTypeId'} type={'select'} label={'Document type'} selectOptions={documentsOptions} handleSelectChange={onSelectChange}
                    placeholder={'Select document type'} />
          <Row justify={'end'}>
            <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
            <Button text={'Save'} loading={areaUpdating} disabled={isSaveDisabled} htmlType={'submit'} />
          </Row>
        </Form>
      )}
    </>
  )
}
export default AddRequiredDocument
