import { getGeoJsonFromUrl } from '@/components/google-map/helpers/getGeoJsonFromUrl'
import { polygonGeometryAffectedCheck } from '@/components/google-map/turf-functions/polygonGeometryAffectedCheck'
import { MapGeometry } from '@/components/google-map/types'
import { lineGeometryAffectedCheck } from '@/components/google-map/turf-functions/lineGeometryAffectedCheck'
import { chunkProject } from '@/components/google-map/helpers/chunkProject'
import { GeometryIntersectionCheckerResult } from '@/types'
import { Feature, FeatureCollection, Geometry, GeometryCollection, Point } from 'geojson'

export const geometryIntersectionChecker = async (
  featureCollection: FeatureCollection<Geometry | GeometryCollection> | null,
  areasData: AreaEntity[] | ProvinceEntity[],
  precision: number, chunkLength: number,
): Promise<GeometryIntersectionCheckerResult | undefined> => {

  const chunkedProjectPointsArray = chunkProject(featureCollection, chunkLength) as Feature<Point>[]
  try {
    const areasGeoJsonsPromisesArray = areasData?.map(async (it) => {
      return {
        id: it?.id,
        featureCollection: await getGeoJsonFromUrl(it?.attributes?.geoJson?.data?.attributes?.url),
      }
    })
    const mapGeometry = await Promise.all(areasGeoJsonsPromisesArray) as MapGeometry[]
    if (mapGeometry?.length) {

      const polygonMapGeometry = mapGeometry?.filter(it => {
        const features = it?.featureCollection?.features
        if (!features || !features.length) return false

        const geometry = features[0]?.geometry as GeometryCollection | Geometry
        if (!geometry) return false

        if (geometry.type === 'Polygon') return true

        if (geometry.type === 'GeometryCollection') {
          const geometryCollection = geometry as GeometryCollection
          return geometryCollection.geometries?.some(geom => geom?.type === 'Polygon')
        }
        return false
      })

      const lineMapGeometry = mapGeometry?.filter(it => {
        const features = it?.featureCollection?.features
        if (!features || !features.length) return false

        const geometry = features[0]?.geometry as GeometryCollection | Geometry
        if (!geometry) return false
        if (geometry.type === 'LineString') return true
        if (geometry.type === 'GeometryCollection') {
          const geometryCollection = geometry as GeometryCollection
          return geometryCollection.geometries?.some(geom => geom?.type === 'LineString')
        }
        return false
      })

      const polygonAreasAffectedCheckResult = polygonGeometryAffectedCheck(polygonMapGeometry, chunkedProjectPointsArray)
      const lineAreasAffectedCheckResult = lineGeometryAffectedCheck(lineMapGeometry, chunkedProjectPointsArray, precision)

      return {
        polygonCheckResult: polygonAreasAffectedCheckResult,
        linesCheckResult: lineAreasAffectedCheckResult,
      }
    } else {
      console.error('Error fetching areas data')
    }

  } catch (e) {
    console.log('Error fetching areas data:', e)
  }
  return
}