import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useApplicationTypesQuery, useCreateCompanyMutation, useOrganizationsQuery } from '@/graphql'
import { Form, message, Row, Spin } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import FormItem from '../../form-item/FormItem'
import Typography from '../../typography/Typography'

const { Title, Paragraph } = Typography

const getInitialType = (param: string) => {
  let defaultType
  switch (param) {
    case 'clients':
      defaultType = 'client'
      break
    case 'service-providers':
      defaultType = 'service_provider'
      break
    case 'government':
      defaultType = 'government'
      break
    default:
      defaultType = 'client'
  }
  return defaultType
}
const CreateCompany: FC = () => {
  const params = useParams()
  const { setIsModalOpen } = useGeneralContext()
  const [currentCompanyType, setCurrentCompanyType] = useState<EnumCompanyCompanytype>()

  const { data: organizationsResponse, loading: organizationsDataLoading } = useOrganizationsQuery({
    fetchPolicy: globalFetchPolicy,
  })
  const organizationsData = organizationsResponse?.organizations?.data

  const organizationsOptions = organizationsData?.map(it => ({
    label: it?.attributes?.name,
    value: it?.id,
  }))

  const [createCompany] = useCreateCompanyMutation({
    refetchQueries: 'active',
  })
  const { data, loading } = useApplicationTypesQuery()
  const applicationTypes = data?.applicationTypes?.data

  const initialValues = {
    companyType: params.key ? getInitialType(params.key) : 'client',
  }
  useEffect(() => {
    if (params.key === 'clients') {
      setCurrentCompanyType('client')
    }
  }, [params.key])
  const onFinish = async (value: Partial<CompanyInput>) => {
    await createCompany({
      variables: {
        input: {
          companyType: value?.companyType,
          name: value?.name,
          vatNumber: value?.vatNumber,
          registrationNumber: value?.registrationNumber,
          address: value?.address,
          applicationType: value?.applicationType,
          companyEmail: value?.companyEmail,
          organization: value?.organization,
        },
      },
      fetchPolicy: globalFetchPolicy,
      onCompleted: () => {
        BlackNotification('Company created successfully')
        setIsModalOpen(false)
      },
      onError: (error) => {
        message.error(error.message)
      },
    })
  }

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const applicationTypeOptions = applicationTypes?.map(it => ({
    label: it?.attributes?.title,
    value: it?.id,
  }))
  const selectOptions: { label: string, value: EnumCompanyCompanytype }[] = [
    {
      label: 'Client company',
      value: 'client',
    },
    {
      label: 'Service provider company',
      value: 'service_provider',
    },
    {
      label: 'Government company',
      value: 'government',
    },
  ]
  const handleTypChange = (type: EnumCompanyCompanytype) => {
    setCurrentCompanyType(type)
  }
  return (
    <>
      <Title level={3} style={{ marginBottom: 8 }}>{'Create a Company'}</Title>
      <Paragraph style={{ marginTop: 0 }} size={'sm'} color={'light-gray'}>
        {'After creating a Company, please do not forget to fulfill a company profile'}
      </Paragraph>
      {loading ? (
        <Spin />
      ) : (
        <Form layout={'vertical'} className={'form-content'} onFinish={onFinish} style={{ paddingTop: 12 }}
              initialValues={initialValues}>
          <FormItem type={'input'} name={'name'} label={'Company Name'} placeholder={'Enter Name'} />
          <FormItem
            type={'select'}
            selectOptions={organizationsOptions}
            placeholder={'Select Company organization'}
            label={'Company organization'}
            name={'organization'}
            loading={organizationsDataLoading}
          />
          <FormItem type={'input-email'} name={'companyEmail'} label={'Company Email'}
                    placeholder={'example@test.com'} />
          <FormItem type={'input'} name={'address'} label={'Company Address'} placeholder={'Enter Company Address'} />
          <FormItem type={'input'} name={'vatNumber'} label={'Vat Number'} placeholder={'Enter Vat Number'} required={false} />
          <FormItem
            type={'input'}
            name={'registrationNumber'}
            label={'Company Registration Number'}
            placeholder={'Enter Company Registration Number'}
            required={false}
          />
          <FormItem
            name={'companyType'}
            type={'select'}
            label={'Company type'}
            placeholder={'Select company type'}
            selectOptions={selectOptions}
            handleSelectChange={handleTypChange as (value: string) => void}
          />
          {(currentCompanyType !== 'client') && (
            <FormItem
              name={'applicationType'}
              type={'select'}
              label={'Application type'}
              placeholder={'Select company application type'}
              selectOptions={applicationTypeOptions}
            />
          )}
          <Row justify={'end'}>
            <Button text={'Cancel'} btnType={'ghost'} onClick={onCancel} style={{ marginRight: 12 }} />
            <Button text={'Create'} btnType={'primary'} htmlType={'submit'} />
          </Row>
        </Form>
      )}
    </>
  )
}
export default CreateCompany
