import BlackNotification from '@/components/blackNotification/BlackNotification'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  useApplicationsQuery,
  useInvoiceQuery,
  usePaymentLinkMutation,
  useUpdateApplicationMutation,
} from '../../../graphql'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'
import { globalFetchPolicy } from '@/components/layout/DashboardLayout'

const { Text, Paragraph } = Typography
const PaymentOptions = () => {
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('id')
  const { setIsModalOpen, currentId } = useGeneralContext()
  const [updateApplication] = useUpdateApplicationMutation()

  const [isLinkLoading, setIsLinkLoading] = useState(false)

  const { data: appData, loading: appDataLoading } = useApplicationsQuery({
    variables: { filter: { uuid: { eq: uuid } } },
  })
  const { data, loading } = useInvoiceQuery({ variables: { id: currentId } })
  const paymentLink = data?.invoice?.data?.attributes?.paymentLink
  const currentApplication = appData?.applications?.data?.[0]


  const onETMClick = async () => {
    currentApplication?.id && await updateApplication({
      fetchPolicy: globalFetchPolicy,
      variables: {
        id: currentApplication?.id,
        data: {
          status: 'proof_of_payment_required',
        },
      },
    })
    BlackNotification(
      'Status of application changed to \'Proof of payment required\'. Please, upload the payment receipt to current invoice.',
    )
    setIsModalOpen(false)
  }
  const onPayNowClick = () => {
    if (paymentLink) {
      window.open(paymentLink, '_blank')
    }
    setIsModalOpen(false)
  }
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Payment options'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w600'}>
        {'Make an immediate payment using secure payment gateway by clicking "Pay Now".'}
      </Paragraph>
      <Paragraph style={{ marginTop: 20 }} size={'sm'}>
        {
          'Or you can use an option for Electronic Transfer of Money (ETM). We\'ll provide payment details. Then receipt will be required to confirm the transaction. '
        }
      </Paragraph>
      <Paragraph style={{ marginTop: 20 }} size={'sm'}>
        {
          'After making the payment, please, click the \'Upload receipt\' button inside Invoice menu to upload the receipt.'
        }
      </Paragraph>
      <div className='btn-block'>
        <Button text={'ETM Payment'} btnType={'text'} onClick={onETMClick} loading={loading} />
        <Button text={'Pay now'} btnType={'primary'} onClick={onPayNowClick} loading={loading} />
      </div>
    </>
  )
}
export default PaymentOptions
