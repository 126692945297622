import { useNavigate } from "react-router"

import Button from "../../button/Button"
import { useGeneralContext } from "../../context/GeneralContext"
import { SvgIcon } from "../../icon"
import Typography from "../../typography/Typography"

const { Paragraph } = Typography

const CreateProjectSuccess = () => {
  const { setIsModalOpen } = useGeneralContext()
  const navigate = useNavigate()
  const onConfirm = () => {
    setIsModalOpen(false)
    navigate("client/projects")
  }
  return (
    <div className={"create-application-success"}>
      <div className='success-icon-wrapper'>
        <SvgIcon type={"success-tick"} />
      </div>
      <Paragraph centered={true} size={"xl"} style={{ maxWidth: "80%" }} weight={"w700"}>
        {"Your project has been created!"}
      </Paragraph>
      <Paragraph centered={true} style={{ margin: "16px 0 0 0" }} size={"sm"}>
        {"You can edit you project by clicking on project card in your dashboard"}
      </Paragraph>
      <div className='btn-block'>
        <Button text={"Okay"} btnType={"primary"} onClick={onConfirm} />
      </div>
    </div>
  )
}
export default CreateProjectSuccess
