import { Col, Result, Row } from 'antd'
import { Component, ErrorInfo, PropsWithChildren } from "react"

type Props = PropsWithChildren
type State = { errorInfo?: ErrorInfo; hasError: boolean }

class ErrorBoundary<T extends Props> extends Component<T, State> {
  private error?: Error

  constructor(props: T) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.error = error
    // You can also log the error to an error reporting service
    console.error(error.message, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Row justify={'center'}>
          <Col xs={24} sm={20} md={12}>
            <Result title={this.error?.name} subTitle={this.error?.message} status='error' />
          </Col>
      </Row>)
    }

    return this.props.children
  }
}

export { ErrorBoundary }
