import { useAuth } from '@/components/context/AuthProvider'
import { FC } from 'react'
import { useNavigate } from 'react-router'

import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import { IconTypes, SvgIcon } from '../../icon'
import Typography from '../../typography/Typography'

type Props = {
  amount: number
  icon: IconTypes
}
const { Text, Paragraph } = Typography
const InfoCard: FC<Props> = ({ icon, amount }) => {
  return (
    <div className={'application-info-card'}>
      <div className='icon-wrapper'>
        <SvgIcon type={icon} />
      </div>
      <div className='info-block'>
        <Paragraph style={{ textTransform: 'uppercase' }}>{'affected'}</Paragraph>
        <Paragraph size={'md'} weight={'w600'}>
          {amount}
        </Paragraph>
      </div>
    </div>
  )
}
const CreateApplicationAdditionalInfo = () => {
  const { role } = useAuth()

  const { setIsModalOpen, setTriggerRefetch } = useGeneralContext()
  const navigate = useNavigate()
  const onConfirm = () => {
    setIsModalOpen(false)
    navigate(`/${role}/applications`)
    setTriggerRefetch(null)
  }
  return (
    <div className={'additional-info'}>
      <Text size={'xl'} weight={'w700'}>
        {'Additional Information'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'}>
        {'To complete the application, how much Service providers will be involved:'}
      </Paragraph>
      <div className='info-block-grid'>
        <InfoCard icon={'tick-info-card'} amount={7} />
        <InfoCard icon={'clipper-info-card'} amount={4} />
      </div>
      <div className='btn-block'>
        <Button text={'Cancel'} btnType={'text'} />
        <Button text={'Confirm'} btnType={'primary'} onClick={onConfirm} />
      </div>
    </div>
  )
}
export default CreateApplicationAdditionalInfo
