import { getGeoJsonFromUrl } from '@/components/google-map/helpers/getGeoJsonFromUrl'
import GeoJsonRender from '@/components/google-map/helpers/MapFeatureRenderes/GeoJsonRender'
import MapLayersRenderer from '@/components/google-map/helpers/MapFeatureRenderes/MapLayersRenderer'
import { mapFitBounds } from '@/components/google-map/helpers/mapFitBounds'
import MapBoxMap from '@/components/google-map/map-box-map/MapBoxMap'
import { FeatureCollection } from 'geojson'
import { FC, MutableRefObject, Ref, useEffect, useRef, useState } from 'react'
import { MapRef } from 'react-map-gl'

type Props = {
  application: ApplicationEntity
  isShowGeneralGeometry: boolean
  isShowUtilityGeometry: boolean
  generalGeometry: AreaEntity[]
  utilityGeometry: AreaEntity[]
  handleGetSnapshot: () => void
  mapRef: MutableRefObject<MapRef>
  snapshotLoading?: boolean
}
const ApprovalMap: FC<Props> = ({snapshotLoading, mapRef, handleGetSnapshot, generalGeometry, utilityGeometry, application, isShowGeneralGeometry, isShowUtilityGeometry }) => {
  const [projectGeoJsonFeature, setProjectGeoJsonFeature] = useState<FeatureCollection | null>(null)



  useEffect(() => {
    const getGeoJsonProjectInfo = async () => {
      const projectsFeature = await getGeoJsonFromUrl(application?.attributes?.geoJson?.data?.attributes?.url)
      setProjectGeoJsonFeature(projectsFeature as unknown as FeatureCollection)
      projectsFeature && mapFitBounds(mapRef, projectsFeature)
    }
    getGeoJsonProjectInfo().then()
  }, [application?.attributes?.geoJson?.data?.attributes?.url])

  return (
    <MapBoxMap
      mapRef={mapRef as Ref<MapRef>}
      isDrawTools={false}
      mapContainerHeight={500}
      isGeometryVisibilityToggle={false}
      isGetSnapshotShow={true}
      handleGetSnapshot={handleGetSnapshot}
      snapshotLoading={snapshotLoading}
      >
      {isShowGeneralGeometry && <MapLayersRenderer geometry={generalGeometry} />}
      {isShowUtilityGeometry && <MapLayersRenderer geometry={utilityGeometry} />}
      {projectGeoJsonFeature && <GeoJsonRender project={projectGeoJsonFeature} />}
    </MapBoxMap>
  )
}

export default ApprovalMap
