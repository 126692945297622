import UserAvatar from '@/components/user-avatar/UserAvatar'
import { getInitials } from '@/helpers/getInitials'
import { longDash } from '@/helpers/globalVariables'
import classnames from 'classnames'
import React, { FC } from 'react'
import DoubleDotsMenu from '../double-dots-menu/DoubleDotsMenu'
import Typography from '../typography/Typography'
import './UserContactCard.less'
import { Col, Row, Space } from 'antd'

const { Text, Paragraph } = Typography

const menu = [
  {
    label: 'Add to task',
    link: '#',
  },
]
type Props = {
  user: UsersPermissionsUserEntity
}
const UserContactCard: FC<Props> = ({ user }) => {

  const avatarImage = user?.attributes?.userProfile?.data?.attributes?.avatar?.data?.attributes?.url
  const userFullName = user?.attributes?.userProfile?.data?.attributes?.fullName || ''
  return (
    <Row className={'user-contact-card'}>
      <Space>
        <UserAvatar avatarImageUrl={avatarImage} userFullName={userFullName || ''} />
            <Col>
              <Text size={'md'} weight={'w700'}>
                {user?.attributes?.userProfile?.data?.attributes?.fullName}
              </Text>
              <Paragraph style={{marginBottom: 2}} color={'light-gray'}>{user?.attributes?.email}</Paragraph>
              <Paragraph style={{marginBottom: 2}} color={'light-gray'}>{user?.attributes?.userProfile?.data?.attributes?.phone?? ''}</Paragraph>
            </Col>
      </Space>
    </Row>
  )
}

export default UserContactCard
