import FormItemDivider from '@/components/form-item/FormItemDivider'
import { SvgIcon } from '@/components/icon'
import { Col, Input, InputRef, Row, Tag } from 'antd'
import classnames from 'classnames'
import { BaseSyntheticEvent, FC, useEffect, useRef, useState } from 'react'
import Button from '../button/Button';
import {TweenOneGroup} from 'rc-tween-one'
import Typography from '../typography/Typography';
import './TagItem.less'
const { Text } = Typography
type Props = {
  label?: string
  buttonText?: string
  handleCreateTag: (tags: string[]) => void
}
const TagItemsGenerator:FC<Props> = ({label, handleCreateTag}) => {
  const [tags, setTags] = useState<string[]>([]);
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);


  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  
  const handleClose = (removedTag: string) => {
    const newTags = tags.filter(tag => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault()
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
      handleCreateTag([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const forMap = (tag: string) => {
    const tagElem = (
      <Tag
        closable
        onClose={e => {
          e.preventDefault();
          handleClose(tag);
        }}
        className={'tag-item'}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };
const inputClasses = classnames({
  'tag-input': true,
  'tag-input-visible': inputVisible,
})
  const tagChild = tags.map(forMap);
  return (
    <Row>
      <Col span={24}>
        <Text font={'regular'}>{label}</Text>
        <Row>
          <Col span={22}>
            <Row align={'middle'} style={{ minHeight: 35 }}>
              {tags?.length ? (
                <TweenOneGroup
                  enter={{
                    scale: 0.8,
                    opacity: 0,
                    type: 'from',
                    duration: 100,
                  }}
                  onEnd={e => {
                    if (e.type === 'appear' || e.type === 'enter') {
                      (e.target as any).style = 'display: inline-block';
                    }
                  }}
                  leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                  appear={false}
                >
                  {tagChild}
                </TweenOneGroup>
              ) : (
                <Text
                  font={'regular'}
                  size={'xs'}
                  weight={'w700'}
                  style={{color: '#b4b4b4'}}
                >{'Add item by pressing button'}
                </Text>
              )}
            </Row>


          </Col>
          <Col span={2}>
            <Row justify={'end'}>
            <Button bordered={true} size={'small'} btnType={'icon'} icon={<SvgIcon type={'plus'}/>} onClick={showInput} />

            </Row>
          </Col>
        </Row>

        <FormItemDivider marginTop={0} marginBottom={8}/>
        {/*{inputVisible && (*/}
          <Input
            ref={inputRef}
            type={'text'}
            size={'middle'}
            style={{ width: '100%' }}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
            className={inputClasses}
            placeholder={'Enter item name'}
          />
          {/*)}*/}
        {/*{!inputVisible && (*/}
        {/*  <Button*/}
        {/*    icon={<SvgIcon type={'plus'}/>}*/}
        {/*    onClick={showInput}*/}
        {/*    block={true}*/}
        {/*    text={buttonText ?? 'Add item'}*/}
        {/*    btnType={'ghost'}*/}
        {/*    size={'small'}*/}
        {/*  />*/}
        {/*)}*/}
      </Col>

    </Row>
  )
}

export default TagItemsGenerator
