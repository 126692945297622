import { APPLICATION_PROGRESS } from '@/helpers/globalVariables'

export const approvalsProgressCalculator = (
  approvalEnvelop: ComponentDataApplicationServiceProviderInput[]
): number => {
  // Remaining percentage (40% should be as pre Documents uploaded and paid status, final 5 percents remaining for the final approval)
  // 100-35-5 = 60
  const remainingPercentage = 100-APPLICATION_PROGRESS.submitted-5;

  // Calculate the percentage per approval item
  const approvalItemPercentage = remainingPercentage / approvalEnvelop.length;

  // Count how many approval items are approved
  const approvedCount = approvalEnvelop.filter(item => item.approved === true).length;

  // Calculate the total progress
  const totalProgress = APPLICATION_PROGRESS.submitted + (approvedCount * approvalItemPercentage);

  return Math.round(totalProgress);
};