import { SvgIcon } from '@/components/icon'
import Typography from '@/components/typography/Typography'
import React from 'react'
import './AppMobilePlaceholder.less'

const { Paragraph } = Typography
const AppMobilePlaceholder = () => {
  return (
    <div className={'mobile-page-container'}>
      <div className='mobile-page-content'>
        <SvgIcon type={'logo-easetech-white'} className={'mobile-page-logo'} />
        <div className='mp-text-wrapper'>
          <Paragraph size={'md'} centered color={'white'}>{'The Application is only available for devices with screen equal or more than 1024px'}</Paragraph>
        </div>
      </div>
    </div>
  )
}

export default AppMobilePlaceholder
