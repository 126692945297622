import './Status.less'

import classnames from 'classnames'
import { FC } from 'react'

import Typography from '../typography/Typography'
import { getStatusName } from '@/helpers/getStatusName'

const { Text } = Typography
export type StatusType =
  EnumApplicationStatus
  | EnumInvoiceStatus
  | EnumProjectStatus
  | EnumUserspermissionsuserAgentcustomerstatus
  | EnumUserspermissionsuserStatus
| EnumKickoffinviteStatus
| EnumKickoffrecordStatus
| EnumComponentdataagentinfoAssignstatus
| EnumAreaStatus
type Props = {
  status: StatusType | undefined
  accented?: boolean
}
const Status: FC<Props> = ({ status, accented = false }) => {
  const markerStyles = classnames({
    'status-marker': true,
    'incomplete': status?.toLowerCase() === 'incomplete' || status?.toLowerCase() === 'applications_incomplete',
    'confirmed': status?.toLowerCase() === 'confirmed',
    'unconfirmed': status?.toLowerCase() === 'unconfirmed',
    'created': status?.toLowerCase() === 'created',
    'paid': status?.toLowerCase() === 'paid',
    'unpaid': status?.toLowerCase() === 'unpaid',
    'affected': status?.toLowerCase() === 'affected',
    'not-affected': status?.toLowerCase() === 'not affected',
    'active': status?.toLowerCase() === 'active',
    'inactive': status?.toLowerCase() === 'inactive',
    'pending': status?.toLowerCase() === 'pending' || status?.toLowerCase() === 'outdated',
    'blocked': status?.toLowerCase() === 'blocked' || status?.toLowerCase() === 'rejected',
    'quote_issued': status?.toLowerCase() === 'quote_issued',
    'outstanding_documents': status?.toLowerCase() === 'outstanding_documents',
    'ready_for_submitting': status?.toLowerCase() === 'ready_for_submitting',
    'proof_of_payment_required': status?.toLowerCase() === 'proof_of_payment_required',
    'approval_pending': status?.toLowerCase() === 'approval_pending',
    'in_progress': status?.toLowerCase() === 'in_progress',
    'draft': status?.toLowerCase() === 'draft',
    'approved': status?.toLowerCase() === 'approved' || status?.toLowerCase() === 'completed',
    'submitted': status?.toLowerCase() === 'submitted',
    'canceled': status?.toLowerCase() === 'canceled' || status?.toLowerCase() === 'stopped',
  })
  const wrapperClasses = classnames({
    'status-item-wrapper': true,
    'accented': accented,
  })

  return (
    <div className={wrapperClasses}>
      <div className={markerStyles} />
      <Text font={'regular'} size={'xs'} weight={'w600'} className={'status-text'}>
        {getStatusName(status)}
      </Text>
    </div>
  )
}

export default Status
