import { Modal, Skeleton } from 'antd'
import './DocCard.less'

import React, { FC, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { ApprovalDetailsMode } from '../../pages/application/content/approval-details/ApprovalDetails'
import { getUrlExtension } from '../../utilities/utilities'
import DoubleDotsMenu from '../double-dots-menu/DoubleDotsMenu'

import { SvgIcon } from '../icon'
import Typography from '../typography/Typography'
import { ImageItem } from '@/types/upload'


type Props = {
  it: ImageItem
  onRemoveItem?: (id: string) => void
  mode?: ApprovalDetailsMode
}
const { Text } = Typography
const UploadedFileDocCardOld: FC<Props> = ({ it, onRemoveItem, mode }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const onDownload = () => {
    if (it?.url) {
      window.open(it?.url)
    }
  }
  const menuItems = [{ label: 'Download file', link: '', onClick: onDownload }]
  if (!mode || (mode && mode !== 'info')) {
    menuItems.push({ label: 'Remove file', link: '', onClick: () => onRemoveItem && onRemoveItem(it?.id as string) },
    )
  }
  const onCardClick = () => {
    setIsOpen(true)
  }
  const extension = it?.url && getUrlExtension(it?.url)

  interface DocumentLoadSuccessData {
    numPages: number;
  }

  function onDocumentLoadSuccess(data: DocumentLoadSuccessData) {
    setPageNumber(data.numPages)
  }

  return (
    <>
      <div className='doc-card' onClick={onCardClick}>
        <div className='icon-wrapper'>
          <SvgIcon type={'doc'} />
        </div>
        <div className='text-box'>
          <Text size={'md'} weight={'w600'}>
            {it?.name ? it?.name : 'No file name'}
          </Text>
          {it?.size && <Text color={'gray'}>{it?.size}</Text>}
        </div>
        <DoubleDotsMenu menuItems={menuItems} />
      </div>
      <Modal
        width={600}
        open={isOpen}
        footer={false}
        onCancel={() => setIsOpen(false)}
        closeIcon={<SvgIcon type={'close-modal'} />}
        className={'preview-modal-window'}
      >
        {(extension === 'jpg' || extension === 'png' || extension === 'jpeg' || extension === 'webp') && (
          <div>
            <img src={it?.url || ''} alt='image' style={{ width: '100%' }} />
          </div>
        )}
        {extension === 'pdf' && (
          <div>
            <Document
              onLoadError={console.error}
              file={{ url: it?.url || '' }}
              options={{ enableXfa: false, cMapPacked: false, disableStream: true, isEvalSupported: false }}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Skeleton active paragraph={{ rows: 12 }} />}
            >
              {Array.from({ length: pageNumber }, (_, index) => (
                <Page key={index + 1} pageNumber={index + 1} width={530} renderTextLayer={false}
                      renderAnnotationLayer={false} />
              ))}
            </Document>
          </div>
        )}
      </Modal>
    </>
  )
}

export default UploadedFileDocCardOld
