import { CoordinatesType } from '@/components/google-map/types'

export const DEFAULT_MAP_CENTER: CoordinatesType = { lng: 25, lat: -29 } //South Africa center
export const DEFAULT_MAP_ZOOM = 6

export const containerStyle = {
  width: '100%',
  height: '100%',
  cursor: 'pointer',
}
export const lineOptions = {
  strokeColor: '#ff316f',
  strokeOpacity: 1,
  strokeWeight: 3,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
}
export const polygonOptions = {
  editable: false,
  draggable: false,
  fillOpacity: 0.1,
  strokeColor: '#ff316f',
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  geodesic: false,
  zIndex: 1,
}