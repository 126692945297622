import { message } from 'antd'
import moment from 'moment'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { usePaymentQuery, useUpdateApplicationMutation, useUpdatePaymentMutation } from '../../../graphql'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const PaymentOptions = () => {
  const { setIsModalOpen, currentId, setTriggerRefetch } = useGeneralContext()
  const [searchParams] = useSearchParams()
  const currentAppUuid = searchParams?.get('id') as string
  const [updatePayment] = useUpdatePaymentMutation()
  const [updateApplication] = useUpdateApplicationMutation()
  const [isLinkLoading, setIsLinkLoading] = useState(false)
  const { data, loading } = usePaymentQuery({ variables: { id: currentId } })
  const paymentData = data?.payment?.data
  const currentAppId = paymentData?.attributes?.application?.data?.id

  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onConfirm = async () => {
    try {
      await updatePayment({
        variables: {
          id: currentId,
          data: {
            invoiceDate: moment(),
          },
        },
      })
      const updatedApp = await updateApplication({
        variables: {
          id: currentAppId,
          data: {
            status: '',
          },
        },
      })
      if (!updatedApp?.data?.updateApplication?.data) {
        message.error('Error updating application')
      }
      BlackNotification('Payment approved successfully. Click the payment menu button to check the generated invoice')
      setIsModalOpen(false)
      setTriggerRefetch(true)
    } catch (e) {
      message.error('Error updating payment data').then()
    }
  }
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Payment approve'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w600'}>
        {'You are going to approve current application payment request.'}
      </Paragraph>
      <Paragraph style={{ marginTop: 20 }} size={'sm'}>
        {
          'After your approval invoice will be generated and and Client can process the payment. After approval click the menu button to review the generated invoice.'
        }
      </Paragraph>
      <div className='btn-block'>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Confirm'} btnType={'primary'} onClick={onConfirm} loading={isLinkLoading || loading} />
      </div>
    </>
  )
}
export default PaymentOptions
