import { useMemo } from 'react';
import moment from 'moment';

const useApprovalEnvelopCopy = (approvalEnvelop: Maybe<ComponentDataApplicationServiceProvider>[]): ComponentDataApplicationServiceProviderInput[] | undefined => {
  return useMemo(() => {

    return approvalEnvelop?.map((it) => {
      const existingAttachments = it?.attachments as unknown as { data: { id: string }[] };
      return {
        approvalDocumentType: it?.approvalDocumentType,
        approved: it?.approved,
        attachments: existingAttachments?.data?.map((attachment) => attachment.id),
        companyId: it?.companyId,
        companyName: it?.companyName,
        companyType: it?.companyType,
        description: it?.description,
        expiryDate: it?.expiryDate ? moment(it?.expiryDate).format('YYYY-MM-DD') : null,
        feedback: it?.feedback ? moment(it?.feedback, 'HH:mm SSS').format('YYYY-MM-DDTHH:mm:ssZ') : null,
        isAffected: it?.isAffected,
        isSiteVisitRequired: it?.isSiteVisitRequired,
      } as ComponentDataApplicationServiceProviderInput;
    }) || [];
  }, [approvalEnvelop]);
};

export default useApprovalEnvelopCopy;
