import { globalFetchPolicy } from '@/components/layout/DashboardLayout'
import { useDeleteKickoffInviteMutation } from '@/graphql'
import { Row } from 'antd'
import { FC } from 'react'
import BlackNotification from '../../blackNotification/BlackNotification'
import Button from '../../button/Button'
import { useGeneralContext } from '../../context/GeneralContext'
import Typography from '../../typography/Typography'

const { Text, Paragraph } = Typography
const DeleteKickoffInvite:FC = () => {
  const { setIsModalOpen, currentId } = useGeneralContext()

  const [deleteInvite, { loading }] = useDeleteKickoffInviteMutation()
  const onCancel = () => {
    setIsModalOpen(false)
  }
  const onDelete = async () => {
    await deleteInvite({
      variables: {
        id: currentId,
      },
      fetchPolicy: globalFetchPolicy,
      refetchQueries: 'active',
      onError: (error) => {
        BlackNotification(`Error deleting invite. ${error.message}`)
      },
      onCompleted: () => {
        BlackNotification('Invite deleted')
        setIsModalOpen(false)
      },
    })
  }
  return (
    <>
      <Text size={'xl'} weight={'w700'}>
        {'Deleting kickoff invite'}
      </Text>
      <Paragraph style={{ marginTop: 32 }} size={'sm'} weight={'w400'}>
        {`You are going to delete current kickoff invite`}
      </Paragraph>
      <Row style={{ paddingTop: 30 }} justify={'end'}>
        <Button text={'Cancel'} btnType={'text'} onClick={onCancel} />
        <Button text={'Delete'} btnType={'primary'} onClick={onDelete} loading={loading} />
      </Row>
    </>
  )
}
export default DeleteKickoffInvite
