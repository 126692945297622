import { notification } from 'antd'
import { SvgIcon } from '../icon'
import './BlackNotification.less'

const BlackNotification = (message: string) => {
  notification.open({
    message,
    placement: 'bottomRight',
    className: 'black-notification',
    closeIcon: <SvgIcon type={'close-notification'} />,
    duration: 3,
  })
}
export default BlackNotification
