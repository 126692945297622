import { getTitleWithIndex } from '@/helpers/getApplicationTitleWithIndex'
import { Col, Input, Row } from 'antd'
import { FC } from 'react'
import Typography from '../typography/Typography'
import './InfoTextItem.less'

const { Text } = Typography
const InfoTextItem:FC<{label: string, text: Maybe<string>}> = ({label, text}) => {
  return (
    <Row>
      <Col span={4}>
        <Text color={'light-gray'}>{`${label}:`}</Text>
      </Col>
      <Col  flex={'auto'}>
        <Input className={'info-item-input'} bordered={false} value={text || ''} />
        {/*<Text weight={'w600'}>{text}</Text>*/}
      </Col>
    </Row>
  )
}

export default InfoTextItem
