import Button from '@/components/button/Button'
import GeocoderControl from '@/components/google-map/geocoder-control/geocoder-control'
import { ValidationResponse } from '@/components/google-map/helpers/validateProjectSize'
import IsSavingMapCover from '@/components/google-map/is-saving-map-cover/IsSavingMapCover'
import BLACK_WHITE_STYLE from '@/components/google-map/map-styles/basic-map-style.json'
import { mapInitialViewSettings, maxBounds, maxZoom, minZoom } from '@/components/google-map/mapInitialSettings'
import { containerStyle } from '@/pages/manager-map/helpers/mapVariables'
import MapboxDraw, { DrawCreateEvent } from '@mapbox/mapbox-gl-draw'
import { Switch, Tooltip } from 'antd'
import { Feature, FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'
import { LngLatBoundsLike } from 'mapbox-gl'
import { FC, LegacyRef, MutableRefObject, PropsWithChildren, Ref, useEffect, useState } from 'react'
import Map, { FullscreenControl, MapRef, MapStyle, NavigationControl, ScaleControl, ViewState, ViewStateChangeEvent } from 'react-map-gl'
import { MapEvent } from 'react-map-gl/dist/es5/types'
import './MapBoxMap.less'
import pindrop from '../images/marker-06.png'


export type ProjectInfo = {
  points: number,
  length: number,
  area: number
}
type MapBoxMapProps = {
  isAreasCreating?: boolean
  isSaving?: boolean
  onDelete?: (e: DrawCreateEvent) => void
  onUpdate?: (e: DrawCreateEvent) => void
  mapRef: Ref<MapRef>
  mapWrapperRef?: MutableRefObject<undefined>
  isShowGeometry?: boolean
  setIsShowGeometry?: (e: boolean) => void
  onMapRefresh?: () => void
  isClearingAllDrawings?: boolean
  projectInfo?: ValidationResponse | null
  isDrawTools?: boolean
  isGeometryVisibilityToggle?: boolean
  setIsMapLoaded?: (isLoaded: boolean) => void
  setMapRef?: (mapRef: Ref<MapRef>) => void
  mapContainerHeight?: number
  onClick?: (event: any) => void
  onZoom?: (event: ViewStateChangeEvent) => void
  viewState?: ViewState
  onLoad?: () => void
  isSaveButtonShow?: boolean
  isSaveLoading?: boolean
  isSaveDisabled?: boolean
  onSave?: () => void
  handleGetSnapshot?: () => void
  handleZoomToProject?: () => void
  isGetSnapshotShow?: boolean
  isZoomToProjectShow?: boolean
  snapshotLoading?: boolean

}
const MapBoxMap: FC<PropsWithChildren & MapBoxMapProps> = ({
                                                             children,
                                                             isAreasCreating,
                                                             isSaving,
                                                             mapRef,
                                                             onDelete,
                                                             onUpdate,
                                                             isShowGeometry,
                                                             setIsShowGeometry,
                                                             onMapRefresh,
                                                             isClearingAllDrawings,
                                                             projectInfo,
                                                             isDrawTools = true,
                                                             isGeometryVisibilityToggle = true,
                                                             mapWrapperRef,
                                                             setIsMapLoaded,
                                                             setMapRef,
                                                             mapContainerHeight = 800,
                                                             onClick,
                                                             onZoom,
                                                             onLoad,
                                                             isSaveButtonShow = false,
                                                             isSaveLoading,
                                                             isSaveDisabled,
                                                             onSave,
                                                             isGetSnapshotShow,
                                                             isZoomToProjectShow,
                                                             handleZoomToProject,
                                                             handleGetSnapshot,
                                                             snapshotLoading,
                                                           }) => {
  const MAPBOX_TOKEN = import.meta.env.WEBSITE_MAPBOX_TOKEN as string

  const [isSimpleStylesChecked, setIsSimpleStylesChecked] = useState(false)
  const [drawInstance, setDrawInstance] = useState<MapboxDraw | null>(null)
  const [drawings, setDrawings] = useState<FeatureCollection<Geometry, GeoJsonProperties> | Feature | null>(null)

  // TODO: Set initial feature in Application Review/Edit mode
  // useEffect(() => {
  //   setDrawings(initFeature as FeatureCollection<Geometry, GeoJsonProperties>)
  // }, [])

  useEffect(() => {
    if (isClearingAllDrawings) {
      drawInstance?.deleteAll()
    }
  }, [isClearingAllDrawings])

  const onStyleSwitchChange = (checked: boolean) => {
    setIsSimpleStylesChecked(checked)
  }
  const onGeometryVisibilityChange = (checked: boolean) => {
    if (setIsShowGeometry) {
      setIsShowGeometry(checked)
    }
  }

  const onMapLoad = async (e: MapEvent<any>) => {
    // setCurrentCanvas(e.target.getCanvas())

    onLoad && onLoad()
    setIsMapLoaded && setIsMapLoaded(true)
    // setMapInstance(e.target.getMap())
    setMapRef && setMapRef(e.target as Ref<MapRef>)

    e.target?.loadImage(pindrop, (error, image) => {
        console.log(image)
        if (error) throw error;
        !!image && e.target?.addImage('custom-marker-icon', image);
        console.log(e.target?.hasImage('custom-marker-icon'))
      }
    );

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      defaultMode: 'simple_select',
      controls: {
        point: true,
        line_string: true,
        polygon: true,
        trash: true,
      },
    })
    if (isClearingAllDrawings) {
      drawInstance?.deleteAll()
    }
    isDrawTools && e.target.addControl(draw)
    e.target.on('draw.create', (e: DrawCreateEvent) => {
      if (e.features?.[0]) {
        setDrawings(e.features?.[0] as Feature)
      }
      onUpdate && onUpdate(e)
    })
    e.target.on('draw.update', onUpdate)
    e.target.on('draw.delete', onDelete)


    drawings && draw.set(drawings as FeatureCollection)
    const uploadedDrawingsIds = isDrawTools && draw?.getAll().features?.map(it => it?.id)

    isDrawTools && setDrawInstance(draw)
    const drawControlContainer = e.target.getContainer().querySelectorAll('.mapboxgl-ctrl-group')[2]

    const refreshButton = document.createElement('button')
    refreshButton.className = 'mapbox-gl-draw_ctrl-draw-btn mapbox-gl-refresh'
    refreshButton.addEventListener('click', () => {
      draw.deleteAll()
      onMapRefresh && onMapRefresh()
    })
    drawControlContainer?.appendChild(refreshButton)
  }

  return (
    <div className={'map-container'}>
      <IsSavingMapCover isVisible={isAreasCreating || isSaving} />
      <div className={'switcher-panel'}>
        <Tooltip placement={'left'} title={isSimpleStylesChecked ? 'Default map styles' : 'Simplify map styles'}>
          <Switch onChange={onStyleSwitchChange} className={'gw-switcher'} />
        </Tooltip>
        {isGeometryVisibilityToggle && (
          <Tooltip placement={'left'} title={isShowGeometry ? 'Hide geometry' : 'Show geometry'}>
            <Switch defaultChecked={false} onChange={onGeometryVisibilityChange} className={'gw-switcher'} />
          </Tooltip>
        )}
        {/*<Button btnType={'primary'} text={'get image'} onClick={onGetImage} />*/}
      </div>
      <div
        id={'map-wrapper'}
        ref={mapWrapperRef as unknown as LegacyRef<HTMLDivElement>}
        style={{ height: mapContainerHeight }}
      >
        <Map
          ref={mapRef}
          mapStyle={(isSimpleStylesChecked ? BLACK_WHITE_STYLE : 'mapbox://styles/mapbox/streets-v11') as MapStyle}
          initialViewState={mapInitialViewSettings}
          mapboxAccessToken={MAPBOX_TOKEN}
          style={containerStyle}
          minZoom={minZoom}
          maxZoom={maxZoom}
          maxBounds={maxBounds as LngLatBoundsLike}
          onLoad={onMapLoad}
          onClick={onClick}
          onZoom={onZoom}
        >
          <div style={{ position: 'absolute', right: 0 }}>
            <FullscreenControl />
            <NavigationControl />
          </div>
          <GeocoderControl mapboxAccessToken={MAPBOX_TOKEN} position={'top-left'} />
          <ScaleControl position={'top-left'}
                        style={{ border: 'none', backgroundColor: 'transparent', borderBottom: '1px solid #8f8f8f', cursor: 'default' }}
                        maxWidth={200} />
          {children}
        </Map>
      </div>
      {projectInfo && (
        <div className={'project-info-panel'}>
          <p>{`Points: ${projectInfo?.points ? projectInfo?.points : '—'}`}</p>
          <p>{`Project Length: ${projectInfo?.length ? projectInfo?.length + ' km' : '—'}`}</p>
          <p>
            <span>{'Project Area:'}</span>
            {projectInfo?.area ? (
              <>
                <span>{` ${projectInfo?.area + ' km'}`}</span>
                <span>&#178;</span>
              </>
            ) : <span>{' —'}</span>
            }
          </p>
          <p>{`Status: ${projectInfo?.isValid ? 'Valid' : 'Invalid'}`}</p>
        </div>
      )}
      <div className={'map-btn-container'}>
        {isZoomToProjectShow && (
          <Button
            text={'Zoom to Project'} btnType={'ghost'}
            onClick={handleZoomToProject}
            style={{ marginRight: 10, borderColor: 'gray' }}
          />
        )}
        {isGetSnapshotShow && (
          <Button text={'Get Snapshot'} onClick={handleGetSnapshot} loading={snapshotLoading}/>
        )}
        {isSaveButtonShow && (
          <Button
            text={'Save'}
            size={'small'}
            loading={isSaveLoading}
            onClick={onSave}
            disabled={isSaveDisabled}
          />
        )}
      </div>
    </div>
  )
}
export default MapBoxMap